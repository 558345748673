export enum ERoutes {
  Root = '/',
  AssessmentsList = '/assessments/list',
  AssessmentsBundles = '/assessments/bundles',
  AssessmentsBundleItem = '/assessments/bundles/:id',
  AssessmentsBundlesVisiblity = '/assessments/bundles-visibility',
  AssessmentPageGeneral = '/assessment/:id/general',
  AssessmentPageQuestions = '/assessment/:id/questions',
  AssessmentPageEntries = '/assessment/:id/entries',
  Translations = '/translations',
  Login = '/login',
}

export const routes = (Object.keys(ERoutes) as Array<keyof typeof ERoutes>).map((key) => ({ path: ERoutes[key] }));

import React, { FC } from 'react';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IQuestionTranslationValue, IQuestionValue, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IMultipleTypeProps {
  question: QuestionWithTranslation;
  className?: string;
  isAnotherLang: boolean;
}

export const MultipleType: FC<IMultipleTypeProps> = ({ className, question, isAnotherLang }) => {
  const [showInput, setShowInput] = React.useState<boolean>(false);
  const [checkedOptionIds, setCheckedOptionIds] = React.useState<string[]>([]);

  const getOtherOption = (id: string | undefined) => {
    const otherOption = question?.values.find((value) => value.id === id && value.isOther === true);
    const translatedOtherOption = question.translations?.values?.find((value) => value.questionValueId === otherOption?.id);
    return { isExist: !!otherOption, option: otherOption, translatedOption: translatedOtherOption };
  };

  const otherOption = question?.values.find((value) => value.isOther === true);
  const noneOfTheAboveOption = question?.values.find((value) => value.isNoneOfTheAbove === true);
  const optionDisabled = noneOfTheAboveOption && checkedOptionIds.includes(noneOfTheAboveOption.id);
  const noneOfTheAboveOptionDisabled =
    noneOfTheAboveOption && !!checkedOptionIds.length && !checkedOptionIds.includes(noneOfTheAboveOption.id);

  const onCheckboxChange = (id: string, e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckedOptionIds((prev) => [...prev, id]);
    } else {
      setCheckedOptionIds((prev) => prev.filter((item) => item !== id));
    }
  };

  const getValueById = (id: string) => {
    return question.values.find((value) => value.id === id)?.value;
  };

  const sortedTranslationsArray = React.useMemo(() => {
    const arr: IQuestionTranslationValue[] = [];
    question.values.forEach((item) => {
      question.translations?.values?.forEach((el) => {
        if (item.id === el.questionValueId) {
          arr.push(el);
        }
      });
    });
    return arr;
  }, [question.values, question.translations?.values]);

  return (
    <Checkbox.Group className={className}>
      {isAnotherLang
        ? sortedTranslationsArray?.map((value) => {
            if (getOtherOption(value.questionValueId).isExist || value.questionValueId === noneOfTheAboveOption?.id) {
              return null;
            } else {
              return (
                <Checkbox
                  key={value.questionValueId}
                  value={value.value ? value.value : getValueById(value.questionValueId)}
                  onChange={(e) => onCheckboxChange(value.questionValueId, e)}
                  disabled={optionDisabled}
                >
                  {value.value ? value.value : getValueById(value.questionValueId)}
                </Checkbox>
              );
            }
          })
        : question.values.map((value: IQuestionValue) => {
            if (value.isOther === true || value.isNoneOfTheAbove === true) {
              return null;
            } else {
              return (
                <Checkbox
                  key={value.id}
                  value={value.value}
                  onChange={(e) => onCheckboxChange(value.id, e)}
                  disabled={optionDisabled}
                >
                  {value.value}
                </Checkbox>
              );
            }
          })}
      {getOtherOption(otherOption?.id).isExist && (
        <Checkbox
          onChange={(e) => {
            onCheckboxChange(otherOption?.id as string, e);
            setShowInput(e.target.checked);
          }}
          value={
            isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
              ? getOtherOption(otherOption?.id).translatedOption?.value
              : otherOption?.value
          }
          disabled={optionDisabled}
        >
          {isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
            ? getOtherOption(otherOption?.id).translatedOption?.value
            : otherOption?.value}
        </Checkbox>
      )}

      {showInput && <Input className="questions__form_common-period-input" placeholder="Please specify" />}

      {noneOfTheAboveOption && isAnotherLang
        ? sortedTranslationsArray?.map((value) => {
            if (value.questionValueId === noneOfTheAboveOption?.id) {
              return (
                <Checkbox
                  key={value.questionValueId}
                  value={value.value ? value.value : getValueById(value.questionValueId)}
                  onChange={(e) => onCheckboxChange(value.questionValueId, e)}
                  disabled={noneOfTheAboveOptionDisabled}
                >
                  {value.value ? value.value : getValueById(value.questionValueId)}
                </Checkbox>
              );
            } else {
              return null;
            }
          })
        : question.values.map((value: IQuestionValue) => {
            if (value.isNoneOfTheAbove === true) {
              return (
                <Checkbox
                  key={value.id}
                  value={value.value}
                  onChange={(e) => onCheckboxChange(value.id, e)}
                  disabled={noneOfTheAboveOptionDisabled}
                >
                  {value.value}
                </Checkbox>
              );
            } else {
              return null;
            }
          })}
    </Checkbox.Group>
  );
};

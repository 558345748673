import React from 'react';
import { Navigate } from 'react-router-dom';
import { ERoutes } from 'common/models/routesModel';
import { PermissionLayout } from 'common/components/Layouts/PermissionLayout';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/auth.communication';

type AllProps = IAuthConnectedProps;

const PrivateLayoutComponent: React.FC<AllProps> = (props) => {
  const { authModel } = props;
  const { data, loading } = authModel;
  const authorized = data?.access;

  if (!authorized && !loading) {
    return <Navigate to={ERoutes.Login} />;
  }

  if (!authorized && loading) {
    return null;
  }

  return <PermissionLayout />;
};

export const PrivateLayout = communicationAuth.injector(PrivateLayoutComponent);

import React, { FC, useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { v4 as uuid } from 'uuid';
import { CloseOutlined } from '@ant-design/icons';
import { IQuestionViewList, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IListTypeProps {
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
}

interface IList {
  title: string;
  placeholder?: string;
  id: string;
}

export const ListType: FC<IListTypeProps> = ({ question, isAnotherLang }) => {
  const listItemTitle: string =
    isAnotherLang && question.translations?.view?.listItemTitle
      ? question.translations.view.listItemTitle
      : (question.view as IQuestionViewList).listItemTitle;

  const listItemPlaceholder: string | undefined =
    isAnotherLang && question.translations?.view?.listItemPlaceholder
      ? question.translations.view.listItemPlaceholder
      : (question.view as IQuestionViewList).listItemPlaceholder;

  const [list, setList] = useState<IList[]>([{ title: listItemTitle || '', placeholder: listItemPlaceholder || '', id: uuid() }]);

  const addItem: () => void = (): void => {
    setList([...list, { title: listItemTitle || '', placeholder: listItemPlaceholder || '', id: uuid() }]);
  };

  const removeItem: (id: string) => void = (id: string): void => {
    const newList: IList[] = list.filter((item) => item.id !== id);
    setList(newList);
  };

  useEffect(() => {
    const listItems = list.map((item) => {
      return {
        ...item,
        title: listItemTitle,
        placeholder: listItemPlaceholder,
      };
    });
    setList(listItems);
  }, [listItemTitle, listItemPlaceholder]);

  return (
    <div className="questions__form_common-list-container">
      {list.map((item) => (
        <div key={item.id} className="questions__form_common-list-block">
          {item.title && <p className="questions__form_common-list-title">{item.title}</p>}
          <div className="questions__form_common-list-input-container">
            <Input placeholder={item.placeholder} className="questions__form_common-list-input" />
            {list.length > 1 && (
              <CloseOutlined className="questions__form_common-list-removeIcon" onClick={() => removeItem(item.id)} />
            )}
          </div>
        </div>
      ))}
      <Button className="questions__form_common-list-btn" type="primary" onClick={addItem}>
        Add
      </Button>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Row, Col, FormInstance } from 'antd';
import { EPalette, EQuestionForm } from 'common/models/questions';
import { defaultDescriptionItems } from 'common/const/nrs.const';
import {
  INrsDescriptionItems,
  IQuestionDto,
  IQuestionTranslation,
  IQuestionViewNrs,
  TNRSDesctiptorKey,
} from 'entities/Questions/questions.model';

interface IComponentProps {
  selectedQuestion: IQuestionDto;
  translatedSelectedQuestion: IQuestionTranslation | null;
  form: FormInstance;
  isAnotherLang: boolean;
}

const { Option } = Select;

export const NRSQuestion: React.FC<IComponentProps> = ({ selectedQuestion, translatedSelectedQuestion, form, isAnotherLang }) => {
  const [showDescriptorsForm, setShowDescriptorsForm] = useState<boolean>(false);
  const view = selectedQuestion.view as IQuestionViewNrs;
  const [descriptionItems, setDescriptionItems] = useState<INrsDescriptionItems>({} as INrsDescriptionItems);

  const renderDescriptionItems = () => {
    if (!view) {
      return null;
    }

    return Object.entries(descriptionItems).map((description) => (
      <Row gutter={12} key={description[0]}>
        <Col span={4}>
          <Form.Item
            name={[EQuestionForm.NRSDescriptorItemValue, description[0]]}
            className="questions__form_settings-slider--row"
            initialValue={description[0]}
          >
            <Input value={description[0]} placeholder="classification" readOnly />
          </Form.Item>
        </Col>

        <Col span={20}>
          <Form.Item
            name={[EQuestionForm.NRSDescriptorItemClassification, description[0]]}
            className="questions__form_settings-slider--row"
            initialValue={description[1].classification}
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input value={description[1].classification} placeholder="classification" />
          </Form.Item>
          <Form.Item
            name={[EQuestionForm.NRSDescriptorItemDescription, description[0]]}
            className="questions__form_settings-slider--row"
            initialValue={description[1].descriptor}
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input value={description[1].descriptor} placeholder="description" />
          </Form.Item>
        </Col>
      </Row>
    ));
  };

  const onShowDescriptorsChange = (value: boolean) => setShowDescriptorsForm(value);

  useEffect(() => {
    setShowDescriptorsForm(view?.showDescription);

    if (!view.description || !Object.entries(view.description).length) {
      setDescriptionItems(defaultDescriptionItems);
    } else {
      const updatedDescriptionItems = {} as INrsDescriptionItems;

      Object.entries(defaultDescriptionItems).forEach((descriptionItem) => {
        if (!view.description?.[descriptionItem[0] as TNRSDesctiptorKey]) {
          updatedDescriptionItems[descriptionItem[0] as TNRSDesctiptorKey] = descriptionItem[1];
        } else {
          updatedDescriptionItems[descriptionItem[0] as TNRSDesctiptorKey] =
            view.description?.[descriptionItem[0] as TNRSDesctiptorKey];
        }
      });

      setDescriptionItems(updatedDescriptionItems);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (isAnotherLang && translatedSelectedQuestion) {
      const translatedView = translatedSelectedQuestion.view;

      Object.keys(descriptionItems).forEach((item) => {
        form.setFields([
          {
            name: [EQuestionForm.NRSDescriptorItemClassification, item],
            value:
              (translatedView?.description?.[item as TNRSDesctiptorKey] &&
                translatedView?.description?.[item as TNRSDesctiptorKey].classification) ||
              '',
          },
          {
            name: [EQuestionForm.NRSDescriptorItemDescription, item],
            value:
              (translatedView?.description?.[item as TNRSDesctiptorKey] &&
                translatedView?.description?.[item as TNRSDesctiptorKey].descriptor) ||
              '',
          },
        ]);
      });
    } else {
      // eslint-disable-next-line sonarjs/no-identical-functions
      Object.entries(descriptionItems).forEach((item) =>
        form.setFields([
          { name: [EQuestionForm.NRSDescriptorItemValue, item[0]], value: item[0] },
          { name: [EQuestionForm.NRSDescriptorItemClassification, item[0]], value: item[1].classification },
          { name: [EQuestionForm.NRSDescriptorItemDescription, item[0]], value: item[1].descriptor },
        ])
      );
    }
  }, [isAnotherLang, descriptionItems, translatedSelectedQuestion]);

  return (
    <>
      <Form.Item name={EQuestionForm.ShowFaceImages} label="Face images" initialValue={view?.showFaceImages}>
        <Select className="questions__form-content__translations" value={view?.showFaceImages} disabled={isAnotherLang}>
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Form.Item>

      <Form.Item name={EQuestionForm.Palette} label="Color type" initialValue={view?.palette || EPalette.Multicolored}>
        <Select className="questions__form-content__translations" value={view?.palette} disabled={isAnotherLang}>
          <Option value={EPalette.Multicolored}>Multicolored</Option>
          <Option value={EPalette.Monochrome}>Monochrome</Option>
        </Select>
      </Form.Item>

      <Form.Item name={EQuestionForm.ShowDescription} label="Description" initialValue={view?.showDescription}>
        <Select
          className="questions__form-content__translations"
          value={view?.showDescription}
          onChange={onShowDescriptorsChange}
          disabled={isAnotherLang}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Form.Item>

      {showDescriptorsForm && (
        <>
          <div className="questions__form_settings-subTitle">Descriptor Items</div>
          {renderDescriptionItems()}
        </>
      )}

      <div className="questions__form_settings-subTitle">Mix / Max Descriptors (Optional)</div>
      <Form.Item
        name={EQuestionForm.NRSMinValueDescriptor}
        label="Min Value Descriptor"
        className="questions__form_settings-slider--row"
        initialValue={view?.minValueDescriptor}
      >
        <Input value={view?.minValueDescriptor} placeholder="Min Text" />
      </Form.Item>
      <Form.Item
        name={EQuestionForm.NRSMaxValueDescriptor}
        label="Max Value Descriptor"
        className="questions__form_settings-slider--row"
        initialValue={view?.maxValueDescriptor}
      >
        <Input value={view?.maxValueDescriptor} placeholder="Max Text" />
      </Form.Item>
    </>
  );
};

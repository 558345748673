import { EOrderDirection, EOrderField, IEntryGetRequest } from 'entities/Entries/entries.model';

interface IHelperParams {
  assessmentId: string;
  currentPage: number;
  orderField: EOrderField;
  orderDirection: EOrderDirection;
  limit: number;
}

export const entriesHelper = (params: IHelperParams): IEntryGetRequest => {
  const result: IEntryGetRequest = {
    assessmentId: params.assessmentId,
    limit: params.limit,
    offset: 0,
    orderField: params.orderField,
    orderDirection: params.orderDirection,
  };

  if (params.currentPage > 1) {
    result.offset = params.limit * (params.currentPage - 1);
  }

  return result;
};

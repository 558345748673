import axios from 'axios';
import { ETranslations } from 'common/models/translations';
import { ITranslationLanguagesCollection, ITranslationsCurrent } from 'entities/Translations/translations.model';

const basePath = '/translation';

export const translationsCollectionTransport = {
  getCollection: (): Promise<ITranslationLanguagesCollection> => axios.get(`${basePath}/languages`),
  getCurrent: (): Promise<ITranslationsCurrent> => axios.get(`${basePath}`),
  update: (params: { translations: ETranslations[] }): Promise<any> => axios.patch(`${basePath}`, params),
};

import React, { ReactNode, useEffect, useState } from 'react';
import Spin from 'antd/es/spin';
import 'antd/es/spin/style/css';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/auth.communication';
import { communicationTags, ITagsConnectedProps } from 'entities/Tags/tags.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationEntries, IEntriesConnectedProps } from 'entities/Entries/entries.communication';
import { communicationHandlers, IHandlersConnectedProps } from 'entities/Handlers/handlers.communication';

interface IComponentProps {
  children?: ReactNode;
}

type AllProps = IComponentProps &
  IEntriesConnectedProps &
  IQuestionsConnectedProps &
  ITagsConnectedProps &
  IAuthConnectedProps &
  IAssessmentsConnectedProps &
  IHandlersConnectedProps;

const LoadingSpinComponent: React.FunctionComponent<AllProps> = (props) => {
  const {
    children,
    entriesCollection,
    questionsCollection,
    tagsCollection,
    authModel,
    assessmentsModel,
    assessmentsCollection,
    assessmentsTranslation,
    handlersExecutionOptionsCollection,
    handlersScoringOptionsCollection,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      entriesCollection.loading ||
      questionsCollection.loading ||
      tagsCollection.loading ||
      authModel.loading ||
      assessmentsModel.loading ||
      assessmentsCollection.loading ||
      assessmentsTranslation.loading ||
      handlersExecutionOptionsCollection.loading ||
      handlersScoringOptionsCollection.loading
    ) {
      if (!loading) {
        setLoading(true);
      }
    } else {
      if (loading) {
        setLoading(false);
      }
    }
  }, [
    entriesCollection.loading,
    questionsCollection.loading,
    tagsCollection.loading,
    authModel.loading,
    assessmentsModel.loading,
    assessmentsCollection.loading,
    assessmentsTranslation.loading,
    handlersExecutionOptionsCollection.loading,
    handlersScoringOptionsCollection.loading,
  ]);

  return (
    <Spin className="loading_spinner" spinning={loading} size="large">
      {children}
    </Spin>
  );
};

export const LoadingSpin = communicationHandlers.injector(
  communicationEntries.injector(
    communicationAssessments.injector(
      communicationQuestions.injector(communicationTags.injector(communicationAuth.injector(LoadingSpinComponent)))
    )
  )
);

export interface ILoginRequest extends Components.Schemas.LoginRequestDto {}
export interface ILoginResponse extends Components.Schemas.LoginResponseDto {}

export interface ILoginRequestWithPersistenceFlag extends ILoginRequest {
  remember: boolean;
}

export enum EAuthErrorCode {
  InvalidCredentials = 'error.userInvalidCredentials',
  UserBlocked = 'error.userBlockedException',
  UserNotConfirmed = 'error.userNotConfirmedException',
}

export enum EAuthErrorMessage {
  InvalidCredentials = 'The password or email address that you have used are incorrect. Please try again.',
  UserBlocked = 'We are sorry but your account might be suspended. If this is a mistake, please contact our MB Go Helpdesk',
  UserNotConfirmed = 'Please verify your email address to log in',
}

export const refreshTokenExpiration = 30 * 86400; // = 30 days

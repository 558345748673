import { APIProvider, BaseStrategy, Branch, buildCommunication, getSuccessType, StoreBranch } from '@axmit/redux-communications';
import { put, takeEvery } from 'redux-saga/effects';
import { RequestLoadingHelper } from 'common/helpers/request.helper';
import { EActionType, ICommonModal, IFormState, ILoadingState, IUnsavedItemsModal } from 'entities/UI/ui.models';

const namespace = 'ui';

export interface IUIConnectedProps {
  uiAddAssessmentModal: StoreBranch<ICommonModal>;
  showUiAddAssessmentModal(): Promise<ICommonModal>;
  hideUiAddAssessmentModal(): Promise<ICommonModal>;

  uiAddQuestionModal: StoreBranch<ICommonModal>;
  showUiAddQuestionModal(): Promise<ICommonModal>;
  hideUiAddQuestionModal(): Promise<ICommonModal>;

  uiCannotDeleteQuestionModal: StoreBranch<ICommonModal>;
  showUiCannotDeleteQuestionModal(): Promise<ICommonModal>;
  hideUiCannotDeleteQuestionModal(): Promise<ICommonModal>;

  uiDeleteQuestionModal: StoreBranch<ICommonModal>;
  showUiDeleteQuestionModal(): Promise<ICommonModal>;
  hideUiDeleteQuestionModal(): Promise<ICommonModal>;

  uiUnsavedItemsModal: StoreBranch<IUnsavedItemsModal>;
  showUiUnsavedItemsModal(params: { actionType?: EActionType; navigateTo?: string }): Promise<IUnsavedItemsModal>;
  hideUiUnsavedItemsModal(): Promise<IUnsavedItemsModal>;
  resetUiUnsavedItemsModal(): Promise<null>;

  uiFormState: StoreBranch<IFormState>;
  setChangedUiFormState(): Promise<IFormState>;
  setUnchangedUiFormState(): Promise<IFormState>;
  setEmptyRequiredFieldsUiFormState(params: { isEmptyRequiredFields: boolean }): Promise<IFormState>;
  setValidationErrorUiFormState(): Promise<IFormState>;
  unsetValidationErrorUiFormState(): Promise<IFormState>;
  setSelectedQuestionUiFormState(params: IFormState): Promise<IFormState>;
  resetUiFormState(): Promise<IFormState>;
  setDepConflictUiFormState(): Promise<IFormState>;
  unsetDepConflictUiFormState(): Promise<IFormState>;

  uiLoadingState: StoreBranch<ILoadingState>;
  setUiLoadingState(): Promise<ILoadingState>;
  unsetUiLoadingState(): Promise<ILoadingState>;
}

const AddAssessmentModalApiProvider = [
  new APIProvider('show', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: true }), {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
  }),
  new APIProvider('hide', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: false }), {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
  }),
];

const FormStateApiProvider = [
  new APIProvider('setChanged', (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, isChanged: true }), {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
  }),
  new APIProvider('setUnchanged', (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, isChanged: false }), {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
  }),
  new APIProvider(
    'setEmptyRequiredFields',
    (params: IFormState): Promise<IFormState> =>
      Promise.resolve({ ...params, isEmptyRequiredFields: params.isEmptyRequiredFields }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
  new APIProvider(
    'setValidationError',
    (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, isUnique: false }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
  new APIProvider(
    'unsetValidationError',
    (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, isUnique: true }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
  new APIProvider('setSelectedQuestion', (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params }), {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
  }),
  new APIProvider(
    'reset',
    (): Promise<IFormState> => Promise.resolve({ isChanged: false, isUnique: true, selectedQuestion: null })
  ),
  new APIProvider(
    'setDepConflict',
    (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, dependencyConflicts: true }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
  new APIProvider(
    'unsetDepConflict',
    (params: IFormState): Promise<IFormState> => Promise.resolve({ ...params, dependencyConflicts: false }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
];

const AddQuestionModalApiProvider = [
  new APIProvider('show', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: true })),
  new APIProvider('hide', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: false })),
];

const CannotDeleteQuestionModalApiProvider = [
  new APIProvider('show', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: true })),
  new APIProvider('hide', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: false })),
];

const DeleteQuestionModalApiProvider = [
  new APIProvider('show', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: true })),
  new APIProvider('hide', (params: ICommonModal): Promise<ICommonModal> => Promise.resolve({ ...params, isVisible: false })),
];

const UnsavedItemsModalApiProvider = [
  new APIProvider(
    'show',
    (params: IUnsavedItemsModal): Promise<IUnsavedItemsModal> => Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider(
    'hide',
    (params: IUnsavedItemsModal): Promise<IUnsavedItemsModal> => Promise.resolve({ ...params, isVisible: false }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      mapSuccess: (response, _, branchState) => ({ ...branchState.data, ...response }),
    }
  ),
  new APIProvider('reset', (): Promise<null> => Promise.resolve(null)),
];

const LoadingStateApiProvider = [
  new APIProvider('set', (): Promise<ILoadingState> => Promise.resolve({ loading: true })),
  new APIProvider('unset', (): Promise<ILoadingState> => Promise.resolve({ loading: false })),
];

const branches = [
  new Branch('addAssessmentModal', AddAssessmentModalApiProvider),
  new Branch('addQuestionModal', AddQuestionModalApiProvider),
  new Branch('cannotDeleteQuestionModal', CannotDeleteQuestionModalApiProvider),
  new Branch('deleteQuestionModal', DeleteQuestionModalApiProvider),
  new Branch('unsavedItemsModal', UnsavedItemsModalApiProvider),
  new Branch('formState', FormStateApiProvider),
  new Branch('loadingState', LoadingStateApiProvider),
];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

function* resetOnLocationChange() {
  yield takeEvery('@@router/LOCATION_CHANGE', function* () {
    const resetSuccessAction = getSuccessType(namespace, 'formState', 'reset');
    yield put({ type: resetSuccessAction, payload: { isChanged: false, isUnique: true } });
  });
}

export const communicationUI = buildCommunication<IUIConnectedProps>(strategy);

communicationUI.sagas.push(resetOnLocationChange());

import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Table, Space, Tag, Pagination, Select } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { DEFAULT_ASSESSMENTS_LIMIT, DEFAULT_DEBOUNCE_DELAY } from 'common/config/config';
import { useDebounce } from 'common/hooks/useDebounce';
import { IAssessmentDto, IAssessmentShortDto } from 'entities/Assessments/assessments.model';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { ITagDto } from 'entities/Tags/tags.model';
import { communicationTags, ITagsConnectedProps } from 'entities/Tags/tags.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
const { Search } = Input;
const { Option } = Select;
type AllProps = IAssessmentsConnectedProps & ITagsConnectedProps & IUIConnectedProps;
const AssessmentsListComponent: React.FC<AllProps> = (props) => {
  const { assessmentsCollection, getAssessmentsCollection, getTagsCollection, uiLoadingState } = props;
  const [assessments, setAssessments] = useState<IAssessmentShortDto[]>(assessmentsCollection.data?.data || []);
  const [searchParams, setSearchParams] = useSearchParams();
  // Backend offset starts from 0
  const offset = Number(searchParams.get('offset')) ?? 0;
  const loading = useMemo(() => uiLoadingState?.data?.loading, [uiLoadingState.data]);
  const [searchName, setSearchName] = useState<string>('');
  const [tags, setTags] = useState<ITagDto[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTag, setSearchTag] = useState<string>('');

  const parsedSelectedTags = selectedTags.join(',');

  const debouncedSearchName = useDebounce(searchName, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    getTagsCollection({ name: searchTag, offset: 0, limit: 10 }).then((response) => {
      setTags(response.data);
    });
    return () => {
      setTags([]);
    };
  }, [searchTag]);

  useEffect(() => {
    getAssessmentsCollection({
      offset: offset * DEFAULT_ASSESSMENTS_LIMIT,
      limit: DEFAULT_ASSESSMENTS_LIMIT,
      name: searchName,
      tags: parsedSelectedTags || undefined,
      exactMatch: false,
    });
  }, [offset]);

  useEffect(() => {
    setSearchParams({ offset: '0' });
    getAssessmentsCollection({
      offset: 0,
      limit: DEFAULT_ASSESSMENTS_LIMIT,
      name: searchName,
      tags: parsedSelectedTags || undefined,
      exactMatch: false,
    });
  }, [parsedSelectedTags, debouncedSearchName]);

  useEffect(() => {
    if (assessmentsCollection.data?.data) {
      setAssessments(assessmentsCollection.data.data);
    }
  }, [assessmentsCollection]);

  const columns = useMemo(
    () => [
      {
        title: 'Assessment name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => <span>{name}</span>,
      },
      {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: (tagsCollection: ITagDto[]) => tagsCollection.map((tag: ITagDto) => <Tag key={tag.id}>{tag.name}</Tag>),
        width: '40%',
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (key: IAssessmentDto) => (
          <Space size="small">
            <Link to={`/assessment/${key.id}/general`}>Edit</Link>
            <Link to={`/assessment/${key.id}/questions`}>Questions</Link>
            <Link to={`/assessment/${key.id}/entries?page=1`}>Entries</Link>
          </Space>
        ),
        className: 'list__table_actions-column',
      },
    ],
    [assessments]
  );
  const changeTags = useCallback(
    (values: string[]) => {
      setSelectedTags(values);
    },
    [selectedTags]
  );
  return (
    <>
      <div className="list__search-wrapper">
        <Search
          className="list__search-name"
          placeholder="Assessment name"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchName(e.target.value)}
        />
        <div className="list__search-tags">
          <Select
            mode="multiple"
            value={selectedTags.map((tag: string) => tag)}
            defaultValue={selectedTags.map((tag: string) => tag)}
            onChange={changeTags}
            onSearch={setSearchTag}
            loading={loading}
            style={{ width: '100%' }}
            placeholder="Tags"
            filterOption={false}
            onBlur={() => setSearchTag('')}
          >
            {tags.map((tag: ITagDto) => (
              <Option key={tag.id} value={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="root-public-page__page">
        <Table className="list__table" columns={columns} dataSource={assessments} pagination={false} />
        <Pagination
          className="list__pagination"
          current={offset ? offset + 1 : 1}
          total={assessmentsCollection.data?.meta.count}
          showSizeChanger={false}
          onChange={(pageId: number) => setSearchParams({ offset: (pageId - 1).toString() })}
          defaultPageSize={DEFAULT_ASSESSMENTS_LIMIT}
        />
      </div>
    </>
  );
};
export const AssessmentsList = communicationAssessments.injector(
  communicationTags.injector(communicationUI.injector(AssessmentsListComponent))
);

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useCurrentPath } from 'common/hooks/useCurrentPath';
import { ERoutes } from 'common/models/routesModel';
import { General } from 'common/components/General';
import { usePage } from 'common/hooks/usePage';
import { Questions } from 'common/components/Questions';
import { Entries } from 'common/components/Entries';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { EActionType } from 'entities/UI/ui.models';

const { TabPane } = Tabs;

type AllProps = IAssessmentsConnectedProps & IUIConnectedProps;

const AssessmentPageComponent: React.FC<AllProps> = (props) => {
  const { uiFormState, showUiUnsavedItemsModal, getAssessmentsModel, assessmentsModel } = props;
  const { data: selectedAssesment } = assessmentsModel;
  const { id } = useParams();
  const { path } = useCurrentPath();
  const page = usePage(path);
  const navigate = useNavigate();
  const [questionsCount, setQuestionsCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (id) {
      getAssessmentsModel({ assessmentId: id });
    }
  }, [id]);

  const pageMapper = useCallback(() => {
    if (selectedAssesment) {
      switch (path) {
        case ERoutes.AssessmentPageGeneral:
          return <General assessment={selectedAssesment} />;
        case ERoutes.AssessmentPageQuestions:
          return <Questions assessment={selectedAssesment} setQuestionsCount={setQuestionsCount} />;
        case ERoutes.AssessmentPageEntries:
          return <Entries assesment={selectedAssesment} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  }, [path, selectedAssesment]);

  const handleTabChange = useCallback(
    (key: string) => {
      if (key === 'entries') {
        key = 'entries?page=1';
      }

      const navigateTo = `/assessment/${id}/${key}`;

      if (uiFormState.data?.isChanged) {
        showUiUnsavedItemsModal({ actionType: EActionType.Navigate, navigateTo });
      } else {
        navigate(navigateTo);
      }
    },
    [id, uiFormState]
  );

  return (
    <>
      <Tabs className="ml-6" activeKey={page} onChange={handleTabChange}>
        <TabPane tab="General" key="general" />
        <TabPane
          tab={
            <span className="d-flex j-center a-center">
              Questions
              {!!questionsCount && (
                <Badge className="ml-2" count={questionsCount} style={{ backgroundColor: '#fff', color: '#1890FF' }} />
              )}
            </span>
          }
          key="questions"
        />
        <TabPane tab="Entries" key="entries" />
      </Tabs>
      <Content className="root-public-page__page">{pageMapper()}</Content>
    </>
  );
};

export const AssessmentPage = communicationUI.injector(communicationAssessments.injector(AssessmentPageComponent));

export interface ICommonModal {
  isVisible: boolean;
}

export const enum EActionType {
  Navigate = 'navigate',
  RemoveQuestion = 'remove_question',
  AddQuestion = 'add_question',
}

export interface IUnsavedItemsModal extends ICommonModal {
  navigateTo: string | null;
  actionType: EActionType | null;
}

export interface IFormState {
  isChanged?: boolean;
  isUnique?: boolean;
  dependencyConflicts?: boolean;
  selectedQuestion?: string | null;
  isEmptyRequiredFields?: boolean;
}

export interface ILoadingState {
  loading: boolean;
}

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LayoutBasic } from 'common/components/Layouts/BasicLayout';
import { CreateNewAssessmentModal } from 'common/components/CreateNewAssessmentModal';
import { CreateNewQuestionModal } from 'common/components/CreateNewQuestionModal';
import { UnsavedItemsModal } from 'common/components/UnsavedItemsModal';
import { CannotDeleteModal, DeleteQuestionModal } from 'common/components/Modals';
import { ERoutes } from 'common/models/routesModel';
import { AssessmentsList } from 'common/components/AssessmentsList';
import { Translations } from 'common/components/Translations';
import { AssessmentPage } from 'app/pages/AssessmentPage';

export const PermissionLayout: React.FC = () => {
  return (
    <>
      <LayoutBasic>
        <Routes>
          <Route path={ERoutes.AssessmentsList} element={<AssessmentsList />} />
          <Route path={ERoutes.AssessmentPageGeneral} element={<AssessmentPage />} />
          <Route path={ERoutes.AssessmentPageQuestions} element={<AssessmentPage />} />
          <Route path={ERoutes.AssessmentPageEntries} element={<AssessmentPage />} />
          <Route path={ERoutes.Translations} element={<Translations />} />
          {/* <Route path={ERoutes.AssessmentsBundles} element={<Bundles />} />
          <Route path={ERoutes.AssessmentsBundleItem} element={<BundleItem />} />
          <Route path={ERoutes.AssessmentsBundlesVisiblity} element={<BundleVisibility />} /> */}
          <Route path="*" element={<Navigate to={ERoutes.AssessmentsList} />} />
        </Routes>
      </LayoutBasic>
      <CreateNewAssessmentModal />
      <CreateNewQuestionModal />
      <UnsavedItemsModal />
      <CannotDeleteModal />
      <DeleteQuestionModal />
    </>
  );
};

export enum EGeneralForm {
  Copyright = 'copyright',
  Control = 'control',
  Logo = 'logo',
  ScoringHandler = 'scoringHandler',
  ExecutionHandler = 'executionHandler',
  ReportHandler = 'reportHandler',
}

export enum ENavigationType {
  None = 'none',
  NextBack = 'nextBack',
  Next = 'next',
}

export enum ENavigationTypeTitle {
  None = 'None',
  NextBack = 'Next/Back',
  Next = 'Next',
}

import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { IAssessmentDto } from 'entities/Assessments/assessments.model';
import { ICreateQuestion, IQuestionDto, IUpdateQuestion, QuestionWithTranslation } from 'entities/Questions/questions.model';
import { questionsCollectionTransport, questionModelTransport } from 'entities/Questions/questions.transport';

const namespace = 'questions';

interface ISelectedQuestionDto {
  question: IQuestionDto | null;
}

export interface IQuestionsConnectedProps {
  questionsCollection: StoreBranch<IAssessmentDto>;
  getQuestionsCollection(params: { assessmentId: string }): Promise<IAssessmentDto>;
  addQuestionsModel(params: { assessmentId: string; newQuestionObject: ICreateQuestion }): Promise<IAssessmentDto>;
  deleteQuestionsModel(params: { questionId: string }): Promise<IQuestionDto>;
  updateQuestionsModel(params: { questionId: string; body: IUpdateQuestion }): Promise<void>;

  questionsSelected: StoreBranch<ISelectedQuestionDto>;
  setQuestionsSelected(params: { question: IQuestionDto | null | QuestionWithTranslation }): Promise<void>;

  questionsRefs: StoreBranch<any>;
  setQuestionsRefs(params: any): Promise<void>;
}

const collectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, questionsCollectionTransport.get, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const modelApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.add, questionModelTransport.add, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.delete, questionModelTransport.delete, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.update, questionModelTransport.update, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const refApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.set, (params: any): Promise<any> => Promise.resolve({ ...params })),
];

const selectedApiProvider: APIProvider[] = [
  new APIProvider(
    'set',
    (params: { question: IQuestionDto | null }): Promise<{ question: IQuestionDto | null }> => Promise.resolve({ ...params })
  ),
];

const branches: Branch[] = [
  new Branch('collection', collectionApiProvider),
  new Branch('model', modelApiProvider),
  new Branch('refs', refApiProvider),
  new Branch('selected', selectedApiProvider),
];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationQuestions = buildCommunication<IQuestionsConnectedProps>(strategy);

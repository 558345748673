import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { handlersTransport } from 'entities/Handlers/handlers.transport';

const namespace = 'handlers';

interface IHandlers {
  handlers: string[];
}

export interface IHandlersConnectedProps {
  handlersScoringOptionsCollection: StoreBranch<IHandlers>;
  getHandlersScoringOptionsCollection(): Promise<IHandlers>;
  handlersExecutionOptionsCollection: StoreBranch<IHandlers>;
  getHandlersExecutionOptionsCollection(): Promise<IHandlers>;
  handlersReportOptionsCollection: StoreBranch<IHandlers>;
  getHandlersReportOptionsCollection(): Promise<IHandlers>;
}

const scoringOptionsCollectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, handlersTransport.getScoringHandlerOptions, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const executionOptionsCollectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, handlersTransport.getExecutionHandlerOptions, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const reportOptionsCollectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, handlersTransport.getReportHandlerOptions, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches: Branch[] = [
  new Branch('scoringOptionsCollection', scoringOptionsCollectionApiProvider),
  new Branch('executionOptionsCollection', executionOptionsCollectionApiProvider),
  new Branch('reportOptionsCollection', reportOptionsCollectionApiProvider),
];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationHandlers = buildCommunication<IHandlersConnectedProps>(strategy);

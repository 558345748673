import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { ITagDto } from 'entities/Tags/tags.model';
import { communicationTags, ITagsConnectedProps } from 'entities/Tags/tags.communication';

interface IComponentProps {
  visible: boolean;
  onCancel: () => void;
  addNewTag: (value: ITagDto) => void;
  selectedTags: ITagDto[];
  allTags: ITagDto[];
  setNewTags: (value: ITagDto[]) => void;
  setNewTag: (value: ITagDto) => void;
}

type AllProps = ITagsConnectedProps & IComponentProps;

export const AddTagModalComponent: React.FC<AllProps> = (props) => {
  const {
    visible,
    onCancel,
    addNewTag,
    selectedTags,
    tagsCollection,
    getTagsCollection,
    tagsModel,
    addTagsModel,
    allTags,
    setNewTag,
    setNewTags,
  } = props;
  const { data: newTag, loading } = tagsModel;
  const [tagName, setTagName] = useState<string>();

  useEffect(() => {
    setTagName('');
  }, [visible]);

  useEffect(() => {
    if (newTag) {
      onCancel();
    }
  }, [newTag?.id]);

  const changeTagName = (e: ChangeEvent<HTMLInputElement>) => {
    setTagName(e.target.value);
    getTagsCollection({ name: e.target.value, offset: 0, limit: 10 });
  };

  const onOk = useCallback(() => {
    if (tagName) {
      if (selectedTags.find((tag: ITagDto) => tag.name === tagName)) {
        onCancel();
      } else {
        const existingTag = tagsCollection.data?.data.find((tag: ITagDto) => tag.name.toLowerCase() === tagName.toLowerCase());
        if (existingTag) {
          addNewTag(existingTag);
          onCancel();
        } else {
          addTagsModel({ name: tagName }).then((newTagModel) => {
            setNewTags([...allTags, newTagModel]);
            setNewTag(newTagModel);
          });
        }
      }
    }
  }, [tagName, addNewTag]);

  const footer = useMemo(
    () => (
      <>
        <Button onClick={onOk} type="primary" disabled={!tagName?.length || loading}>
          Add tag
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </>
    ),
    [tagName, loading, tagsCollection]
  );

  return (
    <Modal visible={visible} footer={footer} closable={false}>
      <Input placeholder="New tag name" value={tagName} onChange={changeTagName} />
    </Modal>
  );
};

export const AddTagModal = communicationTags.injector(AddTagModalComponent);

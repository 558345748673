import React, { FC } from 'react';
import { Button, Card } from 'antd';
import { EPalette } from 'common/models/questions';
import { monochromeColor, nrsLevels } from 'common/const/nrs.const';
import { IQuestionViewNrs, QuestionWithTranslation, TNRSDesctiptorKey } from 'entities/Questions/questions.model';

interface INRSProps {
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
}

export const NRSType: FC<INRSProps> = ({ question, isAnotherLang }) => {
  const [activeValue, setActiveValue] = React.useState<string | null>(null);

  const questionView = question.view as IQuestionViewNrs;
  const faceImages = questionView?.showFaceImages;
  const showDescription = questionView?.showDescription;
  const description = isAnotherLang ? question.translations?.view?.description : questionView?.description;
  const minValueDescriptor = isAnotherLang
    ? question.translations?.view?.minValueDescriptor || ''
    : questionView?.minValueDescriptor;
  const maxValueDescriptor = isAnotherLang
    ? question.translations?.view?.maxValueDescriptor || ''
    : questionView?.maxValueDescriptor;
  const palette = questionView?.palette || EPalette.Multicolored;
  const isMulticolored = palette === EPalette.Multicolored;
  const allOptionsChoiced = faceImages && showDescription;

  const activeStyle = (color: string = '#000') => ({
    backgroundColor: '#fff',
    color,
    boxShadow: '0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08)',
  });

  return (
    <div className="questions__form_common-nrs-columnWrap">
      <div className="questions__form_common-nrs-content-wrap">
        <div className="questions__form_common-container questions__form_common-nrs-painLevel">
          {nrsLevels.map((nrsLevel) => (
            <Button
              className="questions__form_common-btns--noBorder"
              style={
                activeValue === nrsLevel.count
                  ? isMulticolored
                    ? activeStyle(nrsLevel.color)
                    : activeStyle()
                  : isMulticolored
                  ? { backgroundColor: nrsLevel.color, color: '#fff' }
                  : { backgroundColor: monochromeColor, color: '#fff' }
              }
              key={nrsLevel.count}
              shape="circle"
              value={nrsLevel.count}
              size="large"
              onClick={() => setActiveValue(nrsLevel.count)}
            >
              {nrsLevel.count}
            </Button>
          ))}
        </div>
        <div className="questions__form_common-nrs-values-descriptors">
          <p style={{ maxWidth: '100px', color: isMulticolored ? nrsLevels[0].color : monochromeColor }}>{minValueDescriptor}</p>
          <p style={{ maxWidth: '100px', color: isMulticolored ? nrsLevels[nrsLevels.length - 1].color : monochromeColor }}>
            {maxValueDescriptor}
          </p>
        </div>
        {activeValue && (faceImages || showDescription) && (
          <div className="questions__form_common-nrs-optionsContainer" style={allOptionsChoiced ? { gap: '20px' } : {}}>
            {faceImages && (
              <Card>
                <img src={nrsLevels[+activeValue].faceImage} alt="Face" />
              </Card>
            )}
            {showDescription && (
              <div className="questions__form_common-nrs-descriptionContainer">
                <p
                  className="questions__form_common-nrs-descriptionContainer__p-top"
                  style={isMulticolored ? { color: nrsLevels[+activeValue].color } : { color: monochromeColor }}
                >
                  {description?.[activeValue as TNRSDesctiptorKey]
                    ? description?.[activeValue as TNRSDesctiptorKey].classification
                    : ''}
                </p>
                <p className="questions__form_common-nrs-descriptionContainer__p-bottom">
                  {description?.[activeValue as TNRSDesctiptorKey]
                    ? description?.[activeValue as TNRSDesctiptorKey].descriptor
                    : ''}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

import axios from 'axios';
import { ILoginRequestWithPersistenceFlag, ILoginResponse } from 'entities/Auth/auth.model';

const basePath = '/auth';

export const authTransport = {
  refresh: (params: { refreshToken: string }): Promise<ILoginResponse> => axios.put(`${basePath}`, params),
  login: (params: ILoginRequestWithPersistenceFlag): Promise<ILoginResponse> =>
    axios.post(`${basePath}`, { email: params.email, password: params.password }),
};

import { clearCreds, getCreds, ICreds } from '@axmit/axios-patch-jwt';

export const enum EStorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}

export const readCreds = async () => {
  let creds: ICreds = await getCreds();
  let storage: EStorageType | null = EStorageType.LocalStorage;

  if (!creds.access?.token) {
    storage = null;
    const sessionCreds = sessionStorage.getItem('creds');

    if (sessionCreds) {
      storage = EStorageType.SessionStorage;
      creds = JSON.parse(sessionCreds);
    }
  }

  return { creds, storage };
};

export const removeCreds = (storage: EStorageType) => {
  if (storage === EStorageType.LocalStorage) {
    clearCreds();
  } else {
    sessionStorage.removeItem('creds');
  }
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Pagination, PaginationProps, Table, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnsType, TableProps } from 'antd/lib/table/Table';
import { entriesHelper } from 'common/helpers/entries.helper';
import { communicationEntries, IEntriesConnectedProps } from 'entities/Entries/entries.communication';
import { IAssessmentDto, IAssessmentShortDto } from 'entities/Assessments/assessments.model';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { EOrderDirection, EOrderField, IEntryDto } from 'entities/Entries/entries.model';
import { IQuestionAnswerDto, IQuestionAnswerValueDto, IQuestionDto } from 'entities/Questions/questions.model';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';

interface IComponentProps {
  assesment: IAssessmentShortDto;
}

type AllProps = IEntriesConnectedProps & IComponentProps & IUIConnectedProps & IQuestionsConnectedProps;

export const EntriesComponent: React.FC<AllProps> = (props) => {
  const { entriesCollection, getEntriesCollection, assesment, questionsCollection, getQuestionsCollection } = props;
  const { data } = entriesCollection;
  const [orderField, setOrderField] = useState<EOrderField>(EOrderField.SubmissionDate);
  const [orderDirection, setOrderDirection] = useState<EOrderDirection>(EOrderDirection.Desc);
  const [questions, setQuestions] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const currentPage = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const entries = useMemo(() => data?.data, [data?.data]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    getEntriesCollection(
      entriesHelper({ assessmentId: assesment.id, currentPage, orderField, orderDirection, limit: pageSize })
    ).then(() => {
      if (!questionsCollection?.data) {
        getQuestionsCollection({ assessmentId: assesment.id }).then((response: IAssessmentDto) =>
          setQuestions(response.questions.map((entry: IQuestionDto) => entry.label))
        );
      }
    });
  }, [orderDirection, orderField, currentPage, pageSize]);

  useEffect(() => {
    if (entries) {
      setQuestions(entries[0]?.answers?.map((answer: IQuestionAnswerDto) => answer.question.label));
    }
  }, [entries]);

  const handleSortChange = (column: EOrderField) => {
    setOrderField(column);
    setOrderDirection(orderDirection === EOrderDirection.Desc ? EOrderDirection.Asc : EOrderDirection.Desc);
  };

  const scroll: { x?: number | string; y?: number | string } = { y: 420, x: '100vw' };

  const tableProps: TableProps<IEntryDto> = {
    scroll,
    tableLayout: 'auto',
  };

  const columns: ColumnsType<IEntryDto> =
    useMemo(
      () => [
        ...(questions
          ? questions.map((question: string) => ({
              // eslint-disable-next-line react/no-multi-comp
              title: () => (
                <Tooltip title={question} placement="topLeft">
                  {question}
                </Tooltip>
              ),
              dataIndex: ['answers'],
              render: (answers: IQuestionAnswerDto[]) =>
                answers.map(
                  (answer: IQuestionAnswerDto) =>
                    answer.question.label === question &&
                    answer.values.map((value: IQuestionAnswerValueDto) => (
                      // @ts-ignore need to fix models for 'value'
                      <Tooltip key={value.id} title={value?.value?.value} placement="topLeft">
                        {
                          // @ts-ignore need to fix models for 'value'
                          value?.value?.value
                        }
                      </Tooltip>
                    ))
                ),
            }))
          : []),
        {
          title: 'Patient ID',
          dataIndex: 'patientId',
        },
        {
          title: 'Creation date',
          dataIndex: 'createdAt',
          render: (date: string) => <span key={date}>{new Date(date).toLocaleString()}</span>,
          sorter: true,
          sortOrder:
            orderField === EOrderField.CreatedAt ? (orderDirection === EOrderDirection.Desc ? 'descend' : 'ascend') : null,
          showSorterTooltip: false,
          onHeaderCell: () => ({
            onClick: () => handleSortChange(EOrderField.CreatedAt),
          }),
        },
        {
          title: 'Submission date',
          dataIndex: 'submissionDate',
          render: (date: string) => <span key={date}>{new Date(date).toLocaleString()}</span>,
          sorter: true,
          sortOrder:
            orderField === EOrderField.SubmissionDate ? (orderDirection === EOrderDirection.Desc ? 'descend' : 'ascend') : null,
          showSorterTooltip: false,
          onHeaderCell: () => ({
            onClick: () => handleSortChange(EOrderField.SubmissionDate),
          }),
        },
      ],
      [questions, handleSortChange, orderDirection, orderField]
    ) || [];

  const handlePageChange = useCallback(
    (page: number) => {
      navigate(`?page=${page}`);
    },
    [navigate]
  );

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (_, size) => {
    setPageSize(size);
  };

  return entries?.length ? (
    <div className="entries">
      <Table
        {...tableProps}
        className="entries_table"
        columns={columns}
        dataSource={entries}
        pagination={false}
        rowKey={(el: IEntryDto) => el.id}
        scroll={{ x: 'scroll' }}
        size="small"
        bordered
      />
      <Pagination
        className="entries_pagination"
        current={currentPage}
        onChange={handlePageChange}
        total={data?.meta.count}
        pageSize={pageSize}
        onShowSizeChange={onShowSizeChange}
      />
    </div>
  ) : (
    <Empty className="mt-8 mb-8" />
  );
};

export const Entries = communicationQuestions.injector(communicationUI.injector(communicationEntries.injector(EntriesComponent)));

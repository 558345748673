import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ERoutes } from 'common/models/routesModel';
import { PrivateLayout } from 'common/components/Layouts/PrivateLayout';
import { LoginPage } from 'app/pages/Login';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/auth.communication';

type AllProps = IAuthConnectedProps;

const AppComponent: React.FC<AllProps> = (props) => {
  const { initAuthModel } = props;

  useEffect(() => {
    initAuthModel();
  }, []);

  return (
    <Routes>
      <Route path={ERoutes.Login} element={<LoginPage />} />
      <Route path="*" element={<PrivateLayout />} />
    </Routes>
  );
};

export const App = communicationAuth.injector(AppComponent);

import { StoreBranch } from '@axmit/redux-communications';

function setOldData<Data, Params, Errors>(
  response: Data,
  payload: Params,
  branchState: StoreBranch<Data, Params, Errors>
): Data | undefined {
  return branchState.data || undefined;
}

export const RequestLoadingHelper = {
  setOldData,
};

import { message } from 'antd';

export const showValidationErrors = (payload: any) => {
  console.log(payload);

  Array.isArray(payload.data.message)
    ? payload.data.message.forEach((item: any) => {
        const errors: string[] = Object.values(item.constraints);

        if (errors.length) {
          errors.forEach((error) => {
            message.error(error);
          });
        } else {
          message.error('Unexpected error (wrong format)');
        }
      })
    : message.error('Unexpected error (wrong format)');
};

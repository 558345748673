import axios from 'axios';
import { adminPath, basePath } from 'common/const/path.const';
import { IAssessmentDto } from 'entities/Assessments/assessments.model';
import { ICreateQuestion, IQuestionDto, IUpdateQuestion } from 'entities/Questions/questions.model';

export const questionsCollectionTransport = {
  get: (params: { assessmentId: string }): Promise<IAssessmentDto> => axios.get(`${basePath}/${params.assessmentId}`),
};

export const questionModelTransport = {
  add: (params: { assessmentId: string; newQuestionObject: ICreateQuestion }): Promise<IQuestionDto> =>
    axios.post(`${adminPath}/${basePath}/${params.assessmentId}/question`, params.newQuestionObject),
  delete: (params: { questionId: string }): Promise<IQuestionDto> =>
    axios.delete(`${adminPath}/${basePath}/question/${params.questionId}`),
  update: (params: { questionId: string; body: IUpdateQuestion }): Promise<void> =>
    axios.put(`${adminPath}/${basePath}/question/${params.questionId}`, params.body),
};

import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { adminPath, basePath } from 'common/const/path.const';
import {
  IAssessmentCollection,
  IAssessmentCollectionParams,
  IAssessmentDto,
  IAssessmentTranslation,
  ICreateAssessment,
  IUpdateAssessment,
} from 'entities/Assessments/assessments.model';

const translationNamespace = 'translation';

export const assessmentsCollectionTransport = (params: IAssessmentCollectionParams): Promise<IAssessmentCollection> =>
  axios.get(basePath, { params });

export const assessmentModelTransport = {
  get: (params: { assessmentId: string }): Promise<IAssessmentDto> => axios.get(`${basePath}/${params.assessmentId}`),
  add: (params: ICreateAssessment): Promise<IAssessmentDto> => axios.post(`${adminPath}/${basePath}`, params),
  delete: (params: { assessmentId: string }): Promise<void> => axios.delete(`${adminPath}/${basePath}/${params.assessmentId}`),
  update: (params: { assessmentId: string; body: IUpdateAssessment }): Promise<void> =>
    axios.put(`${adminPath}/${basePath}/${params.assessmentId}`, params.body),
  export: (params: { assessmentId: string }): Promise<IAssessmentDto | void> =>
    axios.get(`${adminPath}/${basePath}/${params.assessmentId}/export`),
  import: (params: { file: RcFile }): Promise<void> => {
    const formData = new FormData();
    formData.append('file', params.file);

    return axios.post(`${adminPath}/${basePath}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const assessmentTranslationTransport = {
  get: (params: { lang: string; assessmentId: string }): Promise<IAssessmentTranslation> =>
    axios.get(`${adminPath}/${basePath}/${translationNamespace}/${params.assessmentId}?lang=${params.lang}`),
  update: (params: { assessmentId: string; body: IAssessmentTranslation }): Promise<IAssessmentTranslation> =>
    axios.patch(`${adminPath}/${basePath}/${translationNamespace}/${params.assessmentId}`, params.body),
};

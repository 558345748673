import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { IEntryCollection, IEntryGetRequest } from 'entities/Entries/entries.model';
import { entryGetTransport } from 'entities/Entries/entries.transport';

const namespace = 'entries';

export interface IEntriesConnectedProps {
  entriesCollection: StoreBranch<IEntryCollection>;
  getEntriesCollection(params: IEntryGetRequest): Promise<IEntryCollection>;
}

const collectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, entryGetTransport, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches: Branch[] = [new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationEntries = buildCommunication<IEntriesConnectedProps>(strategy);

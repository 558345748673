import {
  AnyAction,
  applyMiddleware,
  CombinedState,
  combineReducers,
  compose,
  createStore as createReduxStore,
  Reducer,
} from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import reducers, { IApplicationState } from 'app/store/reducers';
import rootSaga from 'app/store/sagas';

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  // other options if needed
});

const createStore = (initialState: IApplicationState) => {
  // Middleware Configuration
  const middleware = [sagaMiddleware, routerMiddleware];

  // Store Enhancers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const windowObject = window as any;
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development' && typeof windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeEnhancers = windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  // Store Instantiation
  const storeReducers: Reducer<CombinedState<IApplicationState>, AnyAction> = combineReducers<IApplicationState>({
    router: routerReducer,
    ...reducers,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  const rootReducer: Reducer<IApplicationState> = (state, action) => {
    return storeReducers(state, action);
  };
  const store = createReduxStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.closeSagas = () => store.dispatch(END);
  return store;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState = (window as any).___INITIAL_STATE__;
export const store = createStore(initialState);
export const history = createReduxHistory(store);

import React from 'react';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { envConfig } from 'common/config/env.config';
import { readCreds } from 'common/helpers/auth.helper';
import { store, history } from 'app/store/createStore';
import 'app/assets/sass/index.scss';
import { App } from 'app/App';
import { authTransport } from 'entities/Auth/auth.transport';
import { refreshTokenExpiration } from 'entities/Auth/auth.model';

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

// @ts-ignore
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};

// Axios initialization
const storage: IAsyncStorage = new WebStorageDecorator(localStorage);
const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth',
};

configureAxiosJWTInterseptors(axiosConfig);

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use((config) => config);

axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== '/auth' && error.response && error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const { creds } = await readCreds();

        if (creds.refresh) {
          const now = Math.round(Date.now() / 1000);
          const rs = await authTransport.refresh({ refreshToken: creds.refresh?.token });

          const { accessToken, refreshToken, expiresIn } = rs;

          const newCreds = {
            access: {
              id: '1',
              userId: '1',
              token: accessToken,
              issuedAt: now,
              expiredAt: expiresIn ? now + expiresIn : 0,
            },
            refresh: {
              id: '1',
              userId: '1',
              token: refreshToken || '',
              issuedAt: now,
              expiredAt: now + refreshTokenExpiration,
            },
          };

          if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${newCreds.access.token}`;
            sessionStorage.setItem('creds', JSON.stringify({ access: newCreds.access, refresh: newCreds.refresh }));
          }

          return axios(originalConfig);
        }
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    if (error.response) {
      const { status, statusText, data } = error.response;
      return Promise.reject({ data, status, statusText });
    } else {
      return Promise.reject(error);
    }
  }
);

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  MOUNT_NODE
);

import { all, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { EErrorStatus } from 'common/models/requestModels';
import { showValidationErrors } from 'common/helpers/error.helper';
import { communicationAssessments } from 'entities/Assessments/assessments.communication';
import { communicationQuestions } from 'entities/Questions/questions.communication';
import { communicationEntries } from 'entities/Entries/entries.communication';
import { communicationHandlers } from 'entities/Handlers/handlers.communication';
import { communicationTags } from 'entities/Tags/tags.communication';
import { communicationUI } from 'entities/UI/ui.communication';
import { communicationAuth } from 'entities/Auth/auth.communication';
import { communicationTranslations } from 'entities/Translations/translations.communication';

function* errorWatcher() {
  yield takeEvery('*', function logger(action: any) {
    if (action.type.match('FAIL')) {
      const status = action.payload && action.payload.status;
      const errorMessage = action.payload && action.payload.message;

      switch (status) {
        case EErrorStatus.Validation:
          showValidationErrors(action.payload);
          break;
        case EErrorStatus.NotConfirmedEmail:
          message.warning('Please check your email and confirm account!');
          break;
        case EErrorStatus.InternalServerError:
          message.warning('Something went wrong');
          break;
        default:
          console.error(action.payload);

          if (errorMessage) {
            message.error(errorMessage);
          } else {
            message.error('Something went wrong. Please contact the dev team if the issue remains.');
          }
          break;
      }
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAssessments.sagas,
    ...communicationQuestions.sagas,
    ...communicationEntries.sagas,
    ...communicationHandlers.sagas,
    ...communicationTags.sagas,
    ...communicationUI.sagas,
    ...communicationAuth.sagas,
    ...communicationTranslations.sagas,
  ]);
}

import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { RequestLoadingHelper } from 'common/helpers/request.helper';
import {
  assessmentsCollectionTransport,
  assessmentModelTransport,
  assessmentTranslationTransport,
} from 'entities/Assessments/assessments.transport';
import {
  IAssessmentCollection,
  IAssessmentCollectionParams,
  IAssessmentDto,
  IAssessmentShortDto,
  IAssessmentTranslation,
  ICreateAssessment,
  IUpdateAssessment,
} from 'entities/Assessments/assessments.model';

const namespace = 'assessments';

export interface IAssessmentsConnectedProps {
  assessmentsCollection: StoreBranch<IAssessmentCollection>;
  getAssessmentsCollection(params: IAssessmentCollectionParams): Promise<IAssessmentCollection>;

  assessmentsModel: StoreBranch<IAssessmentDto>;
  getAssessmentsModel: (params: { assessmentId: string }) => Promise<IAssessmentDto>;
  addAssessmentsModel(params: ICreateAssessment): Promise<IAssessmentDto>;
  deleteAssessmentsModel(params: { assessmentId: string }): Promise<void>;
  updateAssessmentsModel(params: { assessmentId: string; body: IUpdateAssessment }): Promise<IAssessmentShortDto>;
  exportAssessmentsModel(params: { assessmentId: string }): Promise<IAssessmentDto | void>;
  importAssessmentsModel(params: { file: RcFile }): Promise<void>;

  assessmentsTranslation: StoreBranch<IAssessmentTranslation>;
  getAssessmentsTranslation(params: { lang: string; assessmentId: string }): Promise<IAssessmentTranslation>;
  updateAssessmentsTranslation(params: { assessmentId: string; body: IAssessmentTranslation }): Promise<IAssessmentTranslation>;
}

const collectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, assessmentsCollectionTransport, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const translationApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, assessmentTranslationTransport.get, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.update, assessmentTranslationTransport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const modelApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, assessmentModelTransport.get, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.add, assessmentModelTransport.add, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.delete, assessmentModelTransport.delete, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.update, assessmentModelTransport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider('export', assessmentModelTransport.export, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
    onSuccess: (response) => {
      const path = window.URL.createObjectURL(new Blob([JSON.stringify(response, null, 2)]));
      const link = document.createElement('a');
      link.href = path;
      link.setAttribute('download', `${(response as unknown as IAssessmentDto).name}.json`);
      document.body.appendChild(link);
      link.click();
    },
  }),
  new APIProvider('import', assessmentModelTransport.import, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches: Branch[] = [
  new Branch('collection', collectionApiProvider),
  new Branch('model', modelApiProvider),
  new Branch('translation', translationApiProvider),
];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationAssessments = buildCommunication<IAssessmentsConnectedProps>(strategy);

import React, { FC } from 'react';
import { Input, Select } from 'antd';
import {
  IQuestionTranslationValue,
  IQuestionValue,
  IQuestionViewPeriod,
  QuestionWithTranslation,
} from 'entities/Questions/questions.model';

interface IPeriodTypeProps {
  question: QuestionWithTranslation;
  getOptions: (value: IQuestionValue | IQuestionTranslationValue, isAnotherLang?: boolean) => void;
  isAnotherLang: boolean;
}

export const PeriodType: FC<IPeriodTypeProps> = ({ question, getOptions, isAnotherLang }) => {
  const getOtherOption = (id: string | undefined) => {
    const otherOption = question?.values.find((value) => value.id === id && value.isDefault === true);
    const translatedOtherOption = question.translations?.values?.find((value) => value.questionValueId === otherOption?.id);
    return { isExist: !!otherOption, option: otherOption, translatedOption: translatedOtherOption };
  };

  const otherOption = question?.values.find((value) => value.isDefault === true);
  const valuePlaceholder = isAnotherLang
    ? question.translations?.view?.valuePlaceholder
    : (question.view as IQuestionViewPeriod).valuePlaceholder;
  const periodPlaceholder = isAnotherLang
    ? question.translations?.view?.periodPlaceholder
    : (question.view as IQuestionViewPeriod).periodPlaceholder;

  return (
    <div className="questions__form_common-container questions__form_common-period">
      <Input className="questions__form_common-period-input" type="number" placeholder={valuePlaceholder} />
      <Select
        placeholder={periodPlaceholder}
        value={
          isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
            ? getOtherOption(otherOption?.id).translatedOption?.value
            : otherOption?.value
        }
        className="questions__form_common-period-selector"
      >
        {isAnotherLang && question.translations?.values
          ? question.translations.values.map((value) => getOptions(value, isAnotherLang))
          : question.values.map((value) => getOptions(value))}
      </Select>
    </div>
  );
};

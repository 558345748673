import painLevelNone from 'app/assets/images/painLevelNone.png';
import painLevelMild_1 from 'app/assets/images/painLevelMild_1.png';
import painLevelMild_2 from 'app/assets/images/painLevelMild_2.png';
import painLevelModerate from 'app/assets/images/painLevelModerate.png';
import painLevelSevere_1 from 'app/assets/images/painLevelSevere_1.png';
import painLevelSevere_2 from 'app/assets/images/painLevelSevere_2.png';

export const images = {
  painLevelNone,
  painLevelMild_1,
  painLevelMild_2,
  painLevelModerate,
  painLevelSevere_1,
  painLevelSevere_2,
};

import { matchRoutes, Params, RouteMatch, useLocation } from 'react-router-dom';
import { routes } from 'common/models/routesModel';

interface IReturnType {
  path: string | undefined;
  params: Params<string>;
}

export const useCurrentPath = (): IReturnType => {
  const location = useLocation();

  const match: RouteMatch[] = matchRoutes(routes, location) || [];

  return { path: match[0]?.route.path, params: match[0]?.params };
};

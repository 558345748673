import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { translationsCollectionTransport } from 'entities/Translations/translations.transport';
import {
  ITranslationLanguagesCollection,
  ITranslationsCurrent,
  TranslationsValues,
} from 'entities/Translations/translations.model';

const namespace = 'translations';

export interface ITranslationsConnectedProps {
  translationsCollection: StoreBranch<ITranslationLanguagesCollection>;
  getTranslationsCollection(): Promise<ITranslationLanguagesCollection>;

  translationsCurrent: StoreBranch<ITranslationsCurrent>;
  getTranslationsCurrent(): Promise<ITranslationsCurrent>;
  updateTranslationsCurrent(params: { translations: TranslationsValues }): Promise<ITranslationsCurrent>;
}

const collectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, translationsCollectionTransport.getCollection, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const currentApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, translationsCollectionTransport.getCurrent, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.update, translationsCollectionTransport.update, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches: Branch[] = [new Branch('collection', collectionApiProvider), new Branch('current', currentApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationTranslations = buildCommunication<ITranslationsConnectedProps>(strategy);

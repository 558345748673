import React from 'react';
import { Button, Checkbox, Form, Input, Layout, Spin, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/auth.communication';

const { TabPane } = Tabs;

type AllProps = IAuthConnectedProps;

interface IValues {
  email: string;
  password: string;
  remember: boolean;
}

const LoginPageComponent: React.FC<AllProps> = (props) => {
  const { authModel, addAuthModel } = props;
  const { loading } = authModel;
  const navigate = useNavigate();

  const handleFinish = (values: IValues) => {
    addAuthModel(values).then(() => navigate(ERoutes.AssessmentsList));
  };

  return (
    <Layout className="login-page">
      <Content className="login-page_main-block">
        <span className="login-page_logo">Celeri Health</span>
        <Tabs>
          <TabPane className="login-page_tabs" tab="Login" key="login" />
        </Tabs>
        <Form name="basic" onFinish={handleFinish}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please, input your email' }]}>
            <Input prefix={<UserOutlined />} placeholder="Email" suffix={<span style={{ width: '14px' }}></span>} />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Please, input your password' }]}>
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8 }} initialValue={false}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? <Spin className="login_spinner" indicator={<LoadingOutlined spin />} /> : 'Sign In'}
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export const LoginPage = communicationAuth.injector(LoginPageComponent);

import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { stringToSlug } from 'common/utils/stringToSlug';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { IAssessmentShortDto } from 'entities/Assessments/assessments.model';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';

type AllProps = IAssessmentsConnectedProps & IUIConnectedProps;

const CreateNewAssessmentComponent: React.FC<AllProps> = (props) => {
  const {
    assessmentsCollection,
    addAssessmentsModel,
    getAssessmentsCollection,
    uiAddAssessmentModal,
    setValidationErrorUiFormState,
    unsetValidationErrorUiFormState,
    hideUiAddAssessmentModal,
    uiFormState,
  } = props;
  const [assessmentName, setAssessmentName] = useState<string>('');
  const isVisible = useMemo(() => uiAddAssessmentModal.data?.isVisible, [uiAddAssessmentModal]);
  const nameIsUnique = useMemo(() => uiFormState.data?.isUnique, [uiFormState]);
  const assessments = useMemo(() => assessmentsCollection.data?.data, [assessmentsCollection]);

  useEffect(() => {
    if (assessments?.find((assessment: IAssessmentShortDto) => assessment.name === assessmentName)) {
      if (uiFormState.data?.isUnique) {
        setValidationErrorUiFormState();
      }
    } else {
      if (!uiFormState.data?.isUnique) {
        unsetValidationErrorUiFormState();
      }
    }
  }, [assessmentName, assessments]);

  const onCancelWrapper = useCallback(() => {
    setAssessmentName('');
    hideUiAddAssessmentModal();
  }, []);

  const onOk = useCallback(() => {
    addAssessmentsModel({ name: assessmentName, key: stringToSlug(assessmentName, '_'), view: {} }).then(() => {
      getAssessmentsCollection({});
      onCancelWrapper();
    });
  }, [assessmentName]);

  const footer = (
    <>
      <Button onClick={onOk} type="primary" disabled={!nameIsUnique || !assessmentName.length}>
        Add
      </Button>
      <Button onClick={onCancelWrapper}>Cancel</Button>
    </>
  );

  return (
    <Modal visible={isVisible} footer={footer} closable={false}>
      <Form>
        <Form.Item
          help={!nameIsUnique && 'Assessment with this name already exists'}
          validateStatus={!nameIsUnique ? 'error' : ''}
        >
          <Input
            placeholder="New assessment name"
            value={assessmentName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAssessmentName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CreateNewAssessmentModal = communicationUI.injector(communicationAssessments.injector(CreateNewAssessmentComponent));

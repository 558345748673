import React, { useCallback, useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, FormOutlined, TableOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'common/models/routesModel';
import { useCurrentPath } from 'common/hooks/useCurrentPath';

const { Sider } = Layout;

export const PublicSider = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();
  const { path } = useCurrentPath();
  const handleCollapse = () => setCollapsed(!collapsed);

  const menuItems: ItemType[] = useMemo(
    () => [
      {
        key: 'logo',
        className: 'root-public-page__logo',
        onClick: () => navigate(ERoutes.Root),
        label: (
          <>
            <div className="root-public-page__logo-title">Celeri Health</div>
            <div className="root-public-page__logo-icon" />
          </>
        ),
      },
      {
        key: 'assessments',
        label: 'Assessments',
        icon: <FormOutlined />,
        children: [
          {
            key: 'list',
            onClick: () => navigate(ERoutes.AssessmentsList),
            label: 'List',
          },
          // {
          //   key: 'bundles',
          //   onClick: () => navigate(ERoutes.AssessmentsBundles),
          //   label: 'Bundles',
          // },
          // {
          //   key: 'bundleVisibility',
          //   onClick: () => navigate(ERoutes.AssessmentsBundlesVisiblity),
          //   label: 'Bundle Visibility',
          // },
        ],
      },
      {
        key: 'settings',
        label: 'Settings',
        icon: <TableOutlined />,
        children: [
          {
            key: 'translations',
            onClick: () => navigate(ERoutes.Translations),
            label: 'Translations',
          },
        ],
      },
    ],
    []
  );

  const getSelectedKeys = useCallback(() => {
    switch (path) {
      case ERoutes.AssessmentsList:
        return ['list'];
      case ERoutes.AssessmentsBundles:
        return ['bundles'];
      case ERoutes.AssessmentsBundlesVisiblity:
        return ['bundleVisibility'];
      case ERoutes.Translations:
        return ['translations'];
      default:
        return [];
    }
  }, [path]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      theme="light"
      trigger={collapsed ? <MenuUnfoldOutlined onClick={handleCollapse} /> : <MenuFoldOutlined onClick={handleCollapse} />}
    >
      <Menu selectedKeys={getSelectedKeys()} defaultOpenKeys={['assessments']} mode="inline" items={menuItems}></Menu>
    </Sider>
  );
};

import { images } from 'app/assets/images/index';

export const nrsLevels = [
  {
    count: '0',
    color: '#009900',
    faceImage: images.painLevelNone,
  },
  {
    count: '1',
    color: '#27A300',
    faceImage: images.painLevelMild_1,
  },
  {
    count: '2',
    color: '#4FAD00',
    faceImage: images.painLevelMild_1,
  },
  {
    count: '3',
    color: '#73BA02',
    faceImage: images.painLevelMild_2,
  },
  {
    count: '4',
    color: '#85BA00',
    faceImage: images.painLevelMild_2,
  },
  {
    count: '5',
    color: '#999900',
    faceImage: images.painLevelModerate,
  },
  {
    count: '6',
    color: '#CCA300',
    faceImage: images.painLevelModerate,
  },
  {
    count: '7',
    color: '#CC7A00',
    faceImage: images.painLevelSevere_1,
  },
  {
    count: '8',
    color: '#CC5200',
    faceImage: images.painLevelSevere_1,
  },
  {
    count: '9',
    color: '#CC3600',
    faceImage: images.painLevelSevere_2,
  },
  {
    count: '10',
    color: '#CC0000',
    faceImage: images.painLevelSevere_2,
  },
];

export const monochromeColor = '#2767F2';

export const defaultDescriptionItems = {
  '0': {
    classification: '',
    descriptor: '',
  },
  '1': {
    classification: '',
    descriptor: '',
  },
  '2': {
    classification: '',
    descriptor: '',
  },
  '3': {
    classification: '',
    descriptor: '',
  },
  '4': {
    classification: '',
    descriptor: '',
  },
  '5': {
    classification: '',
    descriptor: '',
  },
  '6': {
    classification: '',
    descriptor: '',
  },
  '7': {
    classification: '',
    descriptor: '',
  },
  '8': {
    classification: '',
    descriptor: '',
  },
  '9': {
    classification: '',
    descriptor: '',
  },
  '10': {
    classification: '',
    descriptor: '',
  },
};

import { Button, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { removeQuestion } from 'common/helpers/questions.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';

type AllProps = IUIConnectedProps & IQuestionsConnectedProps & IAssessmentsConnectedProps;

const DeleteQuestionModalComponent: React.FC<AllProps> = (props) => {
  const {
    uiDeleteQuestionModal,
    hideUiDeleteQuestionModal,
    setSelectedQuestionUiFormState,
    uiFormState,
    questionsCollection,
    updateQuestionsModel,
    deleteQuestionsModel,
    getAssessmentsCollection,
    getQuestionsCollection,
  } = props;
  const isVisible = useMemo(() => uiDeleteQuestionModal.data?.isVisible, [uiDeleteQuestionModal]);

  const onOk = useCallback(() => {
    if (uiFormState.data && questionsCollection.data) {
      removeQuestion({
        uiFormState: uiFormState.data,
        questions: questionsCollection.data.questions,
        updateQuestionsModel,
        deleteQuestionsModel,
        setSelectedQuestionUiFormState,
        getAssessmentsCollection,
        assessmentId: questionsCollection.data.id,
        getQuestionsCollection,
      }).then(() => {
        hideUiDeleteQuestionModal();
      });
    }
  }, [uiFormState.data, questionsCollection.data]);

  const onCancelWrapper = useCallback(() => {
    hideUiDeleteQuestionModal();
  }, []);

  const footer = (
    <>
      <Button onClick={onOk}>Yes</Button>
      <Button onClick={onCancelWrapper}>No</Button>
    </>
  );

  return (
    <Modal visible={isVisible} footer={footer} closable={false}>
      <span>Are you sure you want to remove this question?</span>
    </Modal>
  );
};

export const DeleteQuestionModal = communicationUI.injector(
  communicationQuestions.injector(communicationAssessments.injector(DeleteQuestionModalComponent))
);

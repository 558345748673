import React, { useEffect, useState } from 'react';
import { Button, List, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { ETranslations, ETranslationsTitle } from 'common/models/translations';
import { ITranslationsConnectedProps, communicationTranslations } from 'entities/Translations/translations.communication';
import { LanguagesValues, TranslationsValues } from 'entities/Translations/translations.model';

const { Option } = Select;

type AllProps = ITranslationsConnectedProps;

const TranslationsComponent: React.FC<AllProps> = (props) => {
  const {
    getTranslationsCollection,
    translationsCollection,
    translationsCurrent,
    updateTranslationsCurrent,
    getTranslationsCurrent,
  } = props;

  const [translations, setTranslations] = useState<TranslationsValues>(translationsCurrent.data?.translations || []);
  const [languages, setLanguages] = useState<LanguagesValues>(translationsCollection.data?.languages || []);
  const [isShowSelect, setIsShowSelect] = useState<boolean>(false);

  const translationTitles = translations.map((translation) => ETranslationsTitle[translation]);

  useEffect(() => {
    getTranslationsCollection();
    getTranslationsCurrent();
  }, []);

  useEffect(() => {
    if (translationsCollection.data?.languages) {
      setLanguages(translationsCollection.data?.languages);
    }
  }, [translationsCollection]);

  useEffect(() => {
    if (translationsCurrent.data?.translations) {
      setTranslations(translationsCurrent.data.translations);
    }
  }, [translationsCurrent]);

  const showSelect = () => {
    setIsShowSelect(true);
  };

  const handleUpdate = (value: ETranslations) => {
    updateTranslationsCurrent({ translations: [...translations, value] }).then(() => {
      setTranslations([...translations, value]);
      setIsShowSelect(false);
    });
  };

  const handleRemove = (prop: number) => {
    const updatedTranslations = translations.filter((_, index) => index !== prop);
    updateTranslationsCurrent({ translations: updatedTranslations }).then(() => {
      setTranslations(updatedTranslations);
    });
  };

  return (
    <Content className="translations_container root-public-page__page">
      <div className="translations-content_container">
        <p className="translations-title">Languages</p>
        <List
          size="small"
          bordered
          className="translations-content__list"
          dataSource={translationTitles}
          itemLayout="horizontal"
          renderItem={(item, index) => (
            <List.Item
              actions={
                index > 0
                  ? [
                      <CloseOutlined
                        onClick={() => handleRemove(index)}
                        key={index}
                        className="questions__form_common-list-removeIcon"
                      />,
                    ]
                  : undefined
              }
            >
              {item}
            </List.Item>
          )}
        />
        <div className="d-flex fd-column">
          {isShowSelect && (
            <Select className="translations-content-select" onSelect={handleUpdate}>
              {languages.map((language) => (
                <Option key={language} value={language}>
                  {ETranslationsTitle[language]}
                </Option>
              ))}
            </Select>
          )}
          <Button onClick={showSelect}>Add language</Button>
        </div>
      </div>
    </Content>
  );
};
export const Translations = communicationTranslations.injector(TranslationsComponent);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import isEqual from 'lodash.isequal';
import { useNavigate } from 'react-router-dom';
import { AddTagModal } from 'common/components/AddTagModal';
import { EGeneralForm, ENavigationType, ENavigationTypeTitle } from 'common/models/general';
import { ETranslations, ETranslationsTitle } from 'common/models/translations';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { IAssessmentShortDto, IAssessmentTranslation } from 'entities/Assessments/assessments.model';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { communicationTags, ITagsConnectedProps } from 'entities/Tags/tags.communication';
import { ITagDto } from 'entities/Tags/tags.model';
import { communicationHandlers, IHandlersConnectedProps } from 'entities/Handlers/handlers.communication';
import { ITranslationsConnectedProps, communicationTranslations } from 'entities/Translations/translations.communication';

const { Option } = Select;

interface IComponentProps {
  assessment: IAssessmentShortDto;
}

type AllProps = IAssessmentsConnectedProps &
  IUIConnectedProps &
  IComponentProps &
  ITagsConnectedProps &
  IHandlersConnectedProps &
  ITranslationsConnectedProps;

const GeneralComponent: React.FC<AllProps> = (props) => {
  const {
    assessmentsCollection,
    assessment,
    uiFormState,
    setChangedUiFormState,
    setUnchangedUiFormState,
    setValidationErrorUiFormState,
    unsetValidationErrorUiFormState,
    getTagsCollection,
    updateAssessmentsModel,
    handlersScoringOptionsCollection,
    getHandlersScoringOptionsCollection,
    handlersExecutionOptionsCollection,
    getHandlersExecutionOptionsCollection,
    handlersReportOptionsCollection,
    getHandlersReportOptionsCollection,
    uiUnsavedItemsModal,
    uiLoadingState,
    getTranslationsCurrent,
    translationsCurrent,
    getAssessmentsTranslation,
    assessmentsTranslation,
    updateAssessmentsTranslation,
  } = props;
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<IAssessmentShortDto>(assessment);
  const [tags, setTags] = useState<ITagDto[]>(formState.tags);
  const [showAddTag, setShowAddTag] = useState<boolean>(false);
  const nameIsUnique = useMemo(() => uiFormState.data?.isUnique, [uiFormState]);
  const navigate = useNavigate();
  const loading = useMemo(() => uiLoadingState?.data?.loading, [uiLoadingState.data]);
  const copyrightValue = useMemo(() => formState.view?.copyright, [formState]);
  const controlValue = useMemo(() => formState.view?.control, [formState]);
  const logoValue = useMemo(() => formState.view?.logo, [formState]);
  const [newTag, setNewTag] = useState<ITagDto>();
  const [searchTag, setSearchTag] = useState<string>('');
  const [translatedAssessment, setTranslatedAssessment] = useState<IAssessmentTranslation>();
  const [selectedLanguage, setSelectedLanguage] = useState<ETranslations>(ETranslations.en);
  const isAnotherLang = selectedLanguage !== ETranslations.en;
  const { data: translationsData } = translationsCurrent;

  useEffect(() => {
    getTranslationsCurrent();

    (async () => {
      if (!handlersExecutionOptionsCollection.data) {
        await getHandlersExecutionOptionsCollection();
      }

      if (!handlersScoringOptionsCollection.data) {
        await getHandlersScoringOptionsCollection();
      }

      if (!handlersReportOptionsCollection.data) {
        await getHandlersReportOptionsCollection();
      }
    })();
  }, []);

  useEffect(() => {
    getTagsCollection({ name: searchTag, offset: 0, limit: 10 }).then((response) => {
      setTags(response.data);
    });
    return () => {
      setTags([]);
    };
  }, [searchTag]);

  useEffect(() => {
    if (newTag) {
      setFormState({ ...formState, tags: formState.tags.concat(newTag) });
    }
  }, [newTag]);

  useEffect(() => {
    if (!isAnotherLang) {
      if (!isEqual(formState, assessment)) {
        if (!uiFormState.data?.isChanged) {
          setChangedUiFormState();
        }
      } else {
        if (uiFormState.data?.isChanged && !isAnotherLang) {
          setUnchangedUiFormState();
        }
      }
    } else if (translatedAssessment && assessmentsTranslation.data && isAnotherLang) {
      if (!isEqual(translatedAssessment, assessmentsTranslation.data)) {
        if (!uiFormState.data?.isChanged) {
          setChangedUiFormState();
        }
      } else {
        if (uiFormState.data?.isChanged) {
          setUnchangedUiFormState();
        }
      }
    }
  }, [formState, assessmentsTranslation, translatedAssessment]);

  useEffect(() => {
    if (formState && assessment) {
      const existingAssessmentName = assessmentsCollection?.data?.data.find(
        (assessmentIter: IAssessmentShortDto) => assessment.id !== assessmentIter.id && assessmentIter.name === formState.name
      );

      if (existingAssessmentName) {
        if (nameIsUnique) {
          setValidationErrorUiFormState();
        }
      } else {
        if (!nameIsUnique) {
          unsetValidationErrorUiFormState();
        }
      }
    }
  }, [formState]);

  useEffect(() => {
    if (translatedAssessment) {
      form.setFieldsValue({
        copyright: isAnotherLang ? translatedAssessment.view?.copyright || '' : formState.view?.copyright,
      });
    }
  }, [selectedLanguage, isAnotherLang, translatedAssessment]);

  const updateAssessmentData = useCallback(() => {
    updateAssessmentsModel({
      assessmentId: assessment.id,
      body: {
        ...formState,
      },
    }).then((response: IAssessmentShortDto) => {
      setFormState(response);
    });
  }, [formState, assessment, navigate, uiUnsavedItemsModal]);

  const changeTags = useCallback(
    (values: string[]) => {
      setFormState({
        ...formState,
        tags: values.map((value) => JSON.parse(value)),
      });
    },
    [formState]
  );

  const addTag = useCallback(() => {
    setShowAddTag(true);
  }, []);

  const handleControlsChange = useCallback(
    (type: EGeneralForm, value: string) => {
      const view = formState.view;

      if (!isAnotherLang) {
        if (view) {
          setFormState({
            ...formState,
            tags: formState.tags,
            view: { ...view, [type]: value },
          });
        } else {
          setFormState({
            ...formState,
            tags: formState.tags,
            view: { [type]: value },
          });
        }
      } else if (translatedAssessment) {
        setTranslatedAssessment({
          ...translatedAssessment,
          view: {
            ...translatedAssessment.view,
            [type]: value,
          },
        });
      }
    },
    [formState, isAnotherLang, translatedAssessment]
  );

  const updateTranslations = () => {
    if (translatedAssessment) {
      updateAssessmentsTranslation({ assessmentId: assessment.id, body: translatedAssessment });
    }
  };

  const handleValuesChange = useCallback(
    (changedValue: { [key: string]: string | Object }) => {
      const key = Object.keys(changedValue)[0];
      const value = Object.values(changedValue)[0];
      switch (key) {
        case EGeneralForm.Copyright:
        case EGeneralForm.Control:
        case EGeneralForm.Logo:
          return handleControlsChange(key, value as string);
        case EGeneralForm.ScoringHandler:
        case EGeneralForm.ExecutionHandler:
        case EGeneralForm.ReportHandler:
          return setFormState({
            ...formState,
            [key]: value ? value : null,
          });
        default: {
          setFormState({ ...formState, tags: formState.tags, view: formState.view, [key]: value });
        }
      }
    },
    [formState, handleControlsChange]
  );

  const handleChangeLanguage = (value: ETranslations) => {
    setSelectedLanguage(value);

    if (value !== ETranslations.en) {
      setUnchangedUiFormState();

      getAssessmentsTranslation({ lang: value, assessmentId: assessment.id }).then((response) => {
        setTranslatedAssessment(JSON.parse(JSON.stringify(response)));
      });
    }
  };

  return (
    <div className="general__container">
      <Form
        form={form}
        id="form"
        className="general__form"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 9 }}
        onFinish={isAnotherLang ? updateTranslations : updateAssessmentData}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={formState.name}
          help={!nameIsUnique && 'Assessment with this name already exists'}
          validateStatus={!nameIsUnique ? 'error' : ''}
        >
          <Input value={formState.name} disabled={isAnotherLang} />
        </Form.Item>
        <Form.Item label="ID" name="key" initialValue={formState.key}>
          <Input value={formState.key} disabled={isAnotherLang} />
        </Form.Item>
        <Form.Item label="Tags" initialValue={formState.tags.map((tag: ITagDto) => JSON.stringify(tag))}>
          <Select
            mode="multiple"
            value={formState.tags.map((tag: ITagDto) => JSON.stringify(tag))}
            onChange={changeTags}
            loading={loading}
            onSearch={setSearchTag}
            disabled={isAnotherLang}
          >
            {!tags
              ? null
              : tags.map((tag: ITagDto) => (
                  <Option key={tag.id} value={JSON.stringify(tag)}>
                    {tag.name}
                  </Option>
                ))}
          </Select>
          <Button className="general__form__add_tag ml-8" type="primary" onClick={addTag} disabled={isAnotherLang}>
            + Add tag
          </Button>
        </Form.Item>
        <Form.Item label="Scoring handler" name="scoringHandler" initialValue={formState.scoringHandler}>
          <Select value={formState.scoringHandler} allowClear disabled={isAnotherLang}>
            {handlersScoringOptionsCollection.data?.handlers.map((option: string) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Execution handler" name="executionHandler" initialValue={formState.executionHandler}>
          <Select value={formState.executionHandler} allowClear disabled={isAnotherLang}>
            {handlersExecutionOptionsCollection.data?.handlers.map((handler: string) => (
              <Option key={handler} value={handler}>
                {handler}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Report" name="reportHandler" initialValue={formState.reportHandler}>
          <Select value={formState.reportHandler} allowClear disabled={isAnotherLang}>
            {handlersReportOptionsCollection.data?.handlers.map((reportHandler: string) => (
              <Option key={reportHandler} value={reportHandler}>
                {reportHandler}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Copyright" name="copyright" initialValue={copyrightValue}>
          <Input value={copyrightValue} placeholder={isAnotherLang ? formState.view?.copyright : ''} />
        </Form.Item>
        <Form.Item label="Navigation type" name="control" initialValue={controlValue || ENavigationType.None}>
          <Select value={controlValue} disabled={isAnotherLang}>
            <Option value={ENavigationType.NextBack}>{ENavigationTypeTitle.NextBack}</Option>
            <Option value={ENavigationType.Next}>{ENavigationTypeTitle.Next}</Option>
            <Option value={ENavigationType.None}>{ENavigationTypeTitle.None}</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Logo label" name="logo" initialValue={logoValue}>
          <Input value={logoValue} disabled={isAnotherLang} />
        </Form.Item>
      </Form>
      <AddTagModal
        visible={showAddTag}
        onCancel={() => setShowAddTag(false)}
        addNewTag={(existingTag: ITagDto) => setFormState({ ...formState, tags: formState.tags.concat(existingTag) })}
        allTags={tags}
        setNewTags={setTags}
        selectedTags={formState.tags}
        setNewTag={setNewTag}
      />

      <div>
        {translationsData && (
          <Select className="general__translations" value={selectedLanguage} onSelect={handleChangeLanguage}>
            {translationsData.translations.map((translation) => (
              <Option key={translation} value={translation}>
                {ETranslationsTitle[translation]}
              </Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );
};

export const General = communicationHandlers.injector(
  communicationTags.injector(
    communicationUI.injector(communicationAssessments.injector(communicationTranslations.injector(GeneralComponent)))
  )
);

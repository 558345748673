import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationAuth } from 'entities/Auth/auth.communication';
import { communicationEntries, IEntriesConnectedProps } from 'entities/Entries/entries.communication';
import { communicationHandlers, IHandlersConnectedProps } from 'entities/Handlers/handlers.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { communicationTags, ITagsConnectedProps } from 'entities/Tags/tags.communication';
import { communicationTranslations, ITranslationsConnectedProps } from 'entities/Translations/translations.communication';
import { communicationUI } from 'entities/UI/ui.communication';

export interface IApplicationState {
  assessments: IAssessmentsConnectedProps;
  entries: IEntriesConnectedProps;
  handlers: IHandlersConnectedProps;
  questions: IQuestionsConnectedProps;
  tags: ITagsConnectedProps;
  translations: ITranslationsConnectedProps;
  [key: string]: any;
}

const reducers = {
  ...communicationAssessments.reducers,
  ...communicationQuestions.reducers,
  ...communicationEntries.reducers,
  ...communicationHandlers.reducers,
  ...communicationTags.reducers,
  ...communicationUI.reducers,
  ...communicationAuth.reducers,
  ...communicationTranslations.reducers,
};

export default reducers;

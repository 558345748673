import React, { FC, useCallback } from 'react';
import { Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CopyIcon } from 'app/assets/images/svg';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { EActionType } from 'entities/UI/ui.models';
import { QuestionWithTranslation } from 'entities/Questions/questions.model';

interface ILeftPanelProps {
  order: number;
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
}

type AllProps = IAssessmentsConnectedProps & IQuestionsConnectedProps & ILeftPanelProps & IUIConnectedProps;

export const LeftPanelComponent: FC<AllProps> = ({
  order,
  question,
  showUiUnsavedItemsModal,
  uiFormState,
  questionsCollection,
  setSelectedQuestionUiFormState,
  showUiAddQuestionModal,
  setQuestionsSelected,
  showUiCannotDeleteQuestionModal,
  showUiDeleteQuestionModal,
  isAnotherLang,
}) => {
  const removeQuestionWrapper = useCallback(() => {
    const children = question.children;
    if (children && children.length > 0) {
      showUiCannotDeleteQuestionModal();
    } else {
      setSelectedQuestionUiFormState({ selectedQuestion: question.id }).then(() => {
        if (uiFormState.data?.isChanged) {
          showUiUnsavedItemsModal({ actionType: EActionType.RemoveQuestion });
        } else {
          showUiDeleteQuestionModal();
        }
      });
    }
  }, [uiFormState.data, questionsCollection.data]);

  const addCopyQuestion = useCallback(() => {
    setQuestionsSelected({ question });
    if (uiFormState.data?.isChanged) {
      showUiUnsavedItemsModal({ actionType: EActionType.AddQuestion });
    } else {
      showUiAddQuestionModal();
    }
  }, [uiFormState.data, question]);

  return (
    <>
      <Card className="questions__form_order_leftPanel--top">{order + 1}</Card>
      {!isAnotherLang && (
        <>
          <Card className="questions__form_order_leftPanel--middle" onClick={removeQuestionWrapper}>
            <DeleteOutlined style={{ color: '#ff4d4f' }} />
          </Card>
          <Card className="questions__form_order_leftPanel--bottom" onClick={addCopyQuestion}>
            <CopyIcon />
          </Card>
        </>
      )}
    </>
  );
};

export const LeftPanel = communicationUI.injector(
  communicationAssessments.injector(communicationQuestions.injector(LeftPanelComponent))
);

import { Button, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';

type AllProps = IUIConnectedProps;

const CannotDeleteModalComponent: React.FC<AllProps> = (props) => {
  const { uiCannotDeleteQuestionModal, hideUiCannotDeleteQuestionModal } = props;
  const isVisible = useMemo(() => uiCannotDeleteQuestionModal.data?.isVisible, [uiCannotDeleteQuestionModal]);

  const onOk = useCallback(() => {
    hideUiCannotDeleteQuestionModal();
  }, []);

  const footer = <Button onClick={onOk}>OK</Button>;

  return (
    <Modal visible={isVisible} footer={footer} onCancel={onOk}>
      <span>
        Sorry! This question is parent for other questions of the assessment. If you want to remove this question, please first
        separate the dependent questions from the parent question or remove them from the list.
      </span>
    </Modal>
  );
};

export const CannotDeleteModal = communicationUI.injector(CannotDeleteModalComponent);

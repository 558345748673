import React, { ChangeEvent, FC } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { EQuestionType } from 'common/models/questions';
import { IQuestionViewTextArea, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IComponentProps {
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
  handleTextareaChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextareaType: FC<IComponentProps> = ({ question, isAnotherLang, handleTextareaChange }) => {
  const placeholder: string | undefined =
    isAnotherLang && question.translations?.view?.placeholder
      ? question.translations.view.placeholder
      : (question.view as IQuestionViewTextArea).placeholder;

  return (
    <div className="questions__form_common-textarea-wrapper">
      <TextArea
        className="questions__form_common-textarea"
        rows={8}
        placeholder={question.type === EQuestionType.markdown ? 'Enter your answer' : placeholder}
        bordered
        defaultValue={question.values[0]?.value}
        onChange={question.type === EQuestionType.markdown ? handleTextareaChange : undefined}
      />
    </div>
  );
};

import React, { FC } from 'react';
import { Slider } from 'antd';
import { IQuestionViewRange, ISliderOptionsDto, QuestionWithTranslation } from 'entities/Questions/questions.model';
interface ISliderTypeProps {
  question: QuestionWithTranslation;
  orientation?: string;
  isAnotherLang: boolean;
}
export const SliderType: FC<ISliderTypeProps> = ({ orientation, question, isAnotherLang }) => {
  const options = question.options as ISliderOptionsDto;
  const view = question.view as IQuestionViewRange;

  return (
    <div
      className={`questions__form_common-range ${orientation === 'VERTICAL' ? '' : 'questions__form_common-range--horizontal'}`}
    >
      <div className="questions__form_common-range-description">
        <span className="questions__form_common-range-label">
          {isAnotherLang && question.translations?.view?.max?.label ? question.translations.view.max.label : view?.max?.label}
        </span>
        <span>{options.max}</span>
      </div>
      <Slider
        className={orientation === 'VERTICAL' ? 'questions__form_common-slider' : 'questions__form_common-slider--horizontal'}
        vertical={orientation === 'VERTICAL' ? true : false}
        defaultValue={0}
        min={options.min}
        max={options.max}
        step={options.step}
        tooltipVisible={view.isCurrentValueHidden ? false : undefined}
      />
      <div className="questions__form_common-range-description">
        <span className="questions__form_common-range-label">
          {isAnotherLang && question.translations?.view?.min?.label ? question.translations.view.min.label : view?.min?.label}
        </span>
        <span>{options.min}</span>
      </div>
    </div>
  );
};

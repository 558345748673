import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch,
} from '@axmit/redux-communications';
import { message } from 'antd';
import { ICreds, saveCreds } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { put } from 'redux-saga/effects';
import { EStorageType, readCreds, removeCreds } from 'common/helpers/auth.helper';
import { authTransport } from 'entities/Auth/auth.transport';
import {
  EAuthErrorCode,
  EAuthErrorMessage,
  ILoginRequestWithPersistenceFlag,
  ILoginResponse,
  refreshTokenExpiration,
} from 'entities/Auth/auth.model';

const namespace = 'auth';

export interface IAuthConnectedProps {
  authModel: StoreBranch<ICreds>;
  addAuthModel(params: ILoginRequestWithPersistenceFlag): Promise<ILoginResponse>;
  initAuthModel(): Promise<ICreds>;
}

const modelApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.add, authTransport.login, {
    onSuccess: function* (response: ILoginResponse, originalParams: ILoginRequestWithPersistenceFlag | undefined) {
      const now = Math.round(Date.now() / 1000);

      const creds = {
        access: {
          id: '1',
          userId: '1',
          token: response.accessToken,
          issuedAt: now,
          expiredAt: response.expiresIn ? now + response.expiresIn : 0,
        },
        refresh: {
          id: '1',
          userId: '1',
          token: response.refreshToken || '',
          issuedAt: now,
          expiredAt: now + refreshTokenExpiration,
        },
      };

      if (originalParams?.remember) {
        yield saveCreds(creds);
      } else {
        yield sessionStorage.setItem('creds', JSON.stringify(creds));
      }

      yield put({ type: getStartType(namespace, 'model', EActionsTypes.init) });
    },
    onFail(error) {
      if (error?.data.message === EAuthErrorCode.InvalidCredentials) {
        message.error(EAuthErrorMessage.InvalidCredentials);
      }
      if (error?.data.message === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      if (error?.data.message === EAuthErrorCode.UserNotConfirmed) {
        message.error(EAuthErrorMessage.UserNotConfirmed);
      }
    },
  }),
  new APIProvider(
    EActionsTypes.init,
    async (): Promise<ICreds | null> => {
      const { creds, storage } = await readCreds();
      const now = Math.round(Date.now() / 1000);

      if (creds?.refresh?.expiredAt && creds?.refresh?.expiredAt < now) {
        storage && removeCreds(storage);
        return Promise.resolve(null);
      }

      if (creds?.access?.expiredAt && creds?.access?.expiredAt < now) {
        if (creds.refresh?.token) {
          const newToken = await authTransport.refresh({ refreshToken: creds.refresh?.token });

          const newCreds = {
            access: {
              id: '1',
              userId: '1',
              token: newToken.accessToken,
              issuedAt: now,
              expiredAt: newToken.expiresIn ? now + newToken.expiresIn : 0,
            },
            refresh: {
              id: '1',
              userId: '1',
              token: newToken.refreshToken || '',
              issuedAt: now,
              expiredAt: now + refreshTokenExpiration,
            },
          };
          return Promise.resolve({ ...newCreds, storage });
        } else {
          storage && removeCreds(storage);
          return Promise.resolve(null);
        }
      }

      return creds.access?.token ? Promise.resolve({ ...creds, storage }) : Promise.resolve(null);
    },
    {
      onSuccess: function* (response: (ICreds & { storage: EStorageType }) | null) {
        if (response) {
          const { access, refresh, storage } = response;

          if (access?.token) {
            if (storage === EStorageType.LocalStorage) {
              yield saveCreds({ access: access, refresh: refresh });
            } else {
              axios.defaults.headers.common['Authorization'] = `Bearer ${access.token}`;
              yield sessionStorage.setItem('creds', JSON.stringify({ access: access, refresh: refresh }));
            }
          }
        }
      },
    }
  ),
];

const branches: Branch[] = [new Branch('model', modelApiProvider, new StoreBranch<ICreds>(null, null, null, true))];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);

import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { EQuestionType, EQuestionTypeTitle } from 'common/models/questions';
import { getUniqueOrder } from 'common/helpers/questions.helper';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { ICreateQuestion, IQuestionDto, ICreateValueQuestion } from 'entities/Questions/questions.model';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { IAssessmentDto } from 'entities/Assessments/assessments.model';

type AllProps = IUIConnectedProps & IQuestionsConnectedProps;

const CreateNewQuestionComponent: React.FC<AllProps> = (props) => {
  const {
    addQuestionsModel,
    getQuestionsCollection,
    questionsCollection,
    uiAddQuestionModal,
    hideUiAddQuestionModal,
    setValidationErrorUiFormState,
    unsetValidationErrorUiFormState,
    uiFormState,
    questionsRefs,
    questionsSelected,
    setQuestionsSelected,
    updateQuestionsModel,
  } = props;
  const { data } = questionsCollection;
  const isVisible = useMemo(() => uiAddQuestionModal.data?.isVisible, [uiAddQuestionModal]);
  const [questionLabel, setQuestionLabel] = useState<string>('');
  const [questionType, setQuestionType] = useState<EQuestionType>(EQuestionType.singleChoice);
  const selectedQuestion = questionsSelected.data?.question as IQuestionDto;

  useEffect(() => {
    if (data?.questions.find((question: IQuestionDto) => question.label.toLowerCase() === questionLabel.toLowerCase())) {
      if (uiFormState.data?.isUnique) {
        setValidationErrorUiFormState();
      }
    } else {
      if (!uiFormState.data?.isUnique) {
        unsetValidationErrorUiFormState();
      }
    }
  }, [questionLabel]);

  const addCopyQuestion = (assessmentData: IAssessmentDto, newQuestionObject: ICreateQuestion) => {
    addQuestionsModel({ assessmentId: assessmentData.id, newQuestionObject }).then((assessment) => {
      // eslint-disable-next-line prefer-const
      let updateQuestionsRequests: Promise<void>[] = [];
      assessment.questions.forEach((question, index) => {
        if (question.order > selectedQuestion.order && question.label !== newQuestionObject.label) {
          updateQuestionsRequests.push(
            updateQuestionsModel({
              questionId: question.id,
              body: {
                ...question,
                order: index + 1,
              },
            })
          );
        }
      });
      Promise.all(updateQuestionsRequests).then(() => {
        getQuestionsCollection({ assessmentId: assessmentData.id });
      });
      onCancelWrapper();
      setQuestionsSelected({ question: null });
    });
  };

  const onOk = useCallback(() => {
    if (data?.id) {
      if (selectedQuestion) {
        const values: ICreateValueQuestion[] = selectedQuestion.values.map((value) => {
          return {
            value: value.value,
            score: value.score,
            order: value.order,
            isDefault: value.isDefault,
            isOther: value.isOther,
          };
        });
        const newQuestionObject: ICreateQuestion = {
          skipOption: selectedQuestion.skipOption,
          options: {
            ...selectedQuestion.options,
          },
          type: selectedQuestion.type,
          label: questionLabel,
          order: selectedQuestion.order + 1,
          values,
          view: { ...selectedQuestion.view },
        };
        addCopyQuestion(data, newQuestionObject);
      } else {
        const newQuestionObject: ICreateQuestion = {
          type: questionType,
          label: questionLabel,
          order: getUniqueOrder(data.questions),
          options: {
            useSpecific: false,
          },
          values: [],
          view: { orientation: 'VERTICAL' },
        };
        addQuestionsModel({ assessmentId: data.id, newQuestionObject }).then(() => {
          onCancelWrapper();
          getQuestionsCollection({ assessmentId: data.id }).then(() => {
            questionsRefs.data.current[getUniqueOrder(data.questions)].scrollIntoView({ behavior: 'smooth' });
          });
        });
      }
    }
  }, [questionLabel, questionType, data]);

  const onCancelWrapper = useCallback(() => {
    setQuestionLabel('');
    setQuestionType(EQuestionType.singleChoice);
    setQuestionsSelected({ question: null });
    hideUiAddQuestionModal();
  }, []);

  const getQuestionTypeOptions = () => {
    return Object.values(EQuestionType).filter(
      (option) => option !== EQuestionType.list && option !== EQuestionType.slider && option !== EQuestionType.consentForm
    );
  };

  const footer = useMemo(
    () => (
      <>
        <Button onClick={onOk} type="primary" disabled={!questionLabel.length || !uiFormState.data?.isUnique}>
          Save
        </Button>
        <Button onClick={onCancelWrapper}>Cancel</Button>
      </>
    ),
    [onOk, onCancelWrapper, questionLabel, uiFormState]
  );

  return (
    <Modal visible={isVisible} footer={footer} closable={false}>
      <Form>
        <Form.Item
          help={!uiFormState.data?.isUnique && 'Question with this name already exists'}
          validateStatus={!uiFormState.data?.isUnique ? 'error' : ''}
        >
          <Input
            placeholder="New question label"
            value={questionLabel}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuestionLabel(e.target.value)}
          />
        </Form.Item>

        {!selectedQuestion && (
          <>
            <Form.Item>
              <Select value={questionType} onChange={(value) => setQuestionType(value)}>
                {getQuestionTypeOptions().map((type) => (
                  <Select.Option key={type} value={type}>
                    {EQuestionTypeTitle[type]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div>Please note that there are more question types that are only available in the question editing mode</div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export const CreateNewQuestionModal = communicationUI.injector(communicationQuestions.injector(CreateNewQuestionComponent));

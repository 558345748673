import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { ITagCollection, ITagDto } from 'entities/Tags/tags.model';
import { tagsTransport } from 'entities/Tags/tags.transport';

const namespace = 'tags';

export interface ITagsConnectedProps {
  tagsCollection: StoreBranch<ITagCollection>;
  getTagsCollection(param: { name: string; offset: number; limit: number }): Promise<ITagCollection>;
  tagsModel: StoreBranch<ITagDto>;
  addTagsModel(param: { name: string }): Promise<ITagDto>;
}

const collectionApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.get, tagsTransport.getCollection, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const modelApiProvider: APIProvider[] = [
  new APIProvider(EActionsTypes.add, tagsTransport.add, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches: Branch[] = [new Branch('collection', collectionApiProvider), new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationTags = buildCommunication<ITagsConnectedProps>(strategy);

import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { PublicSider } from 'common/components/PublicSider';
import { Header } from 'common/components/Header';
import { LoadingSpin } from 'common/components/LoadingSpin';

const { Content } = Layout;

interface IComponentProps {
  children: ReactNode;
}

export const LayoutBasic: React.FC<IComponentProps> = (props) => {
  const { children } = props;

  return (
    <LoadingSpin>
      <Layout className="root-public-page__layout">
        <PublicSider />
        <div className="root-public-page__main">
          <Header />
          <Content className="root-public-page__content">{children}</Content>
        </div>
      </Layout>
    </LoadingSpin>
  );
};

import '@axmit/chc-ref-api';

export interface IEntryCollection extends Components.Schemas.EntryCollectionDto {}
export interface IEntryDto extends Components.Schemas.EntryDto {}
export interface IEntryGetRequest {
  offset: number;
  limit: number;
  assessmentId: string;
  orderField: EOrderField;
  orderDirection: EOrderDirection;
}

export const enum EOrderField {
  CreatedAt = 'createdAt',
  SubmissionDate = 'submissionDate',
}

export const enum EOrderDirection {
  Desc = 'DESC',
  Asc = 'ASC',
}
